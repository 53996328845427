// tracking.js - track a user location

import { getCurrentGeoPoint } from './geolocation.js';
import { appState } from './state.js';
import { addPolyline, addTarget, moveMapCenter } from './map.js';
import { getGeoPointWithDialog } from './geolocationmodal.js';

// DEBUG tracking
const debugTrackingMode = false;

const TRACKING_INTERVAL = 5_000;

let trackerID;

function trackToUserLocation () {
  // console.log('trackToUserLocation()');

  // don't track while a search is in progress
  if (appState.searching) {
    return;
  }

  // don't track if user recently moved the map manually
  const lastMoveTime = appState.lastMapInteractionTime;
  // if (Date.now() - lastMoveTime < 30000) {
  //   console.log(`trackToUserLocation user moved map recently, ignoring`);
  //   return;
  // }

  getGeoPointWithDialog()

    .then(point => {
      if (!debugTrackingMode) {return point;}

      // for debug test mode, dither move the point a little bit
      console.log('trackToUserLocation dithering the location for testing');
      const delta = 0.003;
      const ditherLat =
          Number((Math.random() * delta).toFixed(5)) - (delta*0.5),
        ditherLon =
          Number((Math.random() * delta).toFixed(5)) - (delta*0.5);
      let {latitude, longitude} = appState.mapTarget;
      latitude += ditherLat;
      longitude += ditherLon;
      return {latitude, longitude};
    })

    .then(point => {
      if (!point) {return;}
      appState.mapTarget = point;

      // add the target
      addTarget(point);

      // move map center when user hasn't recently interacted with map
      if (appState.followMapLocation &&
        Date.now() - lastMoveTime >= 20_000)
      {
        appState.machineMapInteraction = true;
        moveMapCenter(point, appState.zoomlevel);
        appState.machineMapInteraction = false;
        appState.mapCenter = point;
      }
    });
}

/**
 * turn location tracking on/off
 * @param {Boolean} setting
 */
function setLocationTracking (setting) {

  // console.log(`setLocationTracking(${setting})`);

  if (setting === true) {
    // start tracker
    if (trackerID) {clearInterval(trackerID);}
    trackerID = setInterval(trackToUserLocation, TRACKING_INTERVAL);
    // track once immediately, without waiting for setInterval
    trackToUserLocation();
  } else {
    // disable tracker
    if (trackerID) {
      clearInterval(trackerID);
      trackerID = undefined;
    }
  }
}

export {
  setLocationTracking
};
