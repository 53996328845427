// landing page (screen) for whereami

import * as el from './pageelements.js';
import { appState } from './state.js';
import { displayPanel } from './panels.js';
import { handleMapMoveToUserLocation } from './mappanel.js';
import { setLocationTracking } from './tracking.js';

let dismissButtonClicked = false;


/**
 * hide landing screen
 */
function hideLanding () {
  // console.log('hideLanding()');
  el.divLanding.classList.add('d-none');
  el.divLanding.classList.remove('fade-out');
  el.divLanding.removeEventListener('transitionend', hideLanding);
  el.btnLanding.removeEventListener('transitionend', forceDismissBtnClick);

  // reveal the bottom nav bar
  el.divNav.classList.remove('d-none');

  // reveal the current (or default) panel
  const panelName = appState.panel;
  displayPanel(panelName);
  appState.firstUsage = false;
}

/**
 * true if HTML element is visible
 * @param {HTMLElement} myEl
 * @return {Boolean}
 */
function isElementVisible (myEl) {
  return !myEl.classList.contains('d-none') &&
    !myEl.classList.contains('invisible');
}

/**
 * click landing dismiss button
 */
function forceDismissBtnClick () {
  const delay = 1500;
  if (dismissButtonClicked) {return;}

  setTimeout(() => {
    handleLandingDismissBtn();
  }, delay);
}

// handle landing page "dismiss" button
function handleLandingDismissBtn () {
  // console.log('handleLandingDismissBtn()');
  el.divLanding.classList.add('fade-out');
  el.divLanding.addEventListener('transitionend', hideLanding);
  dismissButtonClicked = true;

  // turn on location tracking
  // handleMapMoveToUserLocation();
  setLocationTracking(true);
}

el.btnLanding.addEventListener('click', handleLandingDismissBtn);

// when landing screen fade-in is complete
function landingFadeInComplete () {
  // console.log('landingFadeInComplete()');
  el.btnLanding.classList.remove('invisible');
  el.divLanding.removeEventListener('transitionend', landingFadeInComplete);

  // if this is a new user, let them click the dismiss button
  if (appState.firstUsage) {return;}

  // not user's first time, click the dismiss button for them
  el.btnLanding.addEventListener('transitionend', forceDismissBtnClick);
}

/**
 * display the landing screen
 */
function displayLanding () {
  // console.log('displayLanding()');

  el.divLanding.addEventListener('transitionend', landingFadeInComplete);

  // display version number on landing screen
  const metaVersion = document.querySelector('meta[name="version"]');
  const version = (metaVersion) ? metaVersion.content : 'UNKNOWN VERSION!';
  const divVersion = document.querySelector('#div-version');
  if (divVersion) {
    divVersion.innerHTML = `Vers: ${version}`;
  }

  // make visible after a short delay
  setTimeout(() => {
    window.scroll(0, 0);
    el.divLanding.classList.remove('invisible');
  }, 0);

  // a "just in case" fallback - hide landing screen after a long wait
  setTimeout(() => {
    if (!isElementVisible(el.divLanding)) {return;}
    if (isElementVisible(el.btnLanding)) {return;}

    console.log('performing "fall back" landing screen hide');
    hideLanding();
  }, 10000);
}

export {
  displayLanding
};

