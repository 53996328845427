// closestitem.js - search array with the smallest `shortestDistance` property

/**
 * get iten with the smallest `shortestDistance` property
 * @param {Array<Node|Way|Building>} items
 * @return {Node|Way|null}
 */
export function getClosestItem (items) {
  return items.reduce((closest, item) => {
    return (closest === null ||
      item.shortestDistance < closest.shortestDistance)
      ? item
      : closest;
  }, null);
}
