// roads.js - extract roads from raw JavaScript data

import { overpassDataToWays } from './overpass-common.js';
import { toWords } from './numtowords.js';
import './typedefs.js';
import { getSetting } from './settings.js';
import { wayNaming } from './waynaming.js';

// factors to convert speed from meters per second
const convertFromMpS = {
  kph: 0.277778,
  mph: 0.44704
};

const unitsInWords = {
  kph: 'kilometers per hour',
  mph: 'miles per hour'
};

/**
 * extract roads from raw JavaScript data
 * @param {Object} jsData
 * @param {{latitude: number, longitude: number}} myPoint
 * @return {Way[]}
 */
export function js2Roads (
  jsData,
  myPoint
) {
  // form an array of roads from rawJS
  let itemsRaw = overpassDataToWays(jsData, myPoint);

  const roads = itemsRaw.filter(item => {
    // Edit 2022-01-01 extract all the roads
    return ('highway' in item);
    // return ('highway' in item) &&
    //   ('name' in item || 'ref' in item || 'destination' in item);
  });

  // set `speedlimit` and `speedUnits` props if we can
  roads.forEach(road => {
    // convert any `maxspeed` values to metric
    if ('maxspeed' in road) {
      let [val, units] = road.maxspeed.split(' ');
      val = Number(val);
      if (!units || !(units in convertFromMpS)) {units = 'kph';}
      road.speedlimit = val * convertFromMpS[units];
      road.speedUnits = units;
    } else {
      road.speedlimit = null;
    }

  });

  return roads;
}

/**
 * form the html & plaintext version of road information
 * @param {Object|null} road
 * @return {{plainText: string, htmlStr: string, failText: string}}
 */
export function roadInfoToDisplay (road) {
  let htmlStr = '', plainText = '', failText = '', highways = null;

  if (!getSetting('showRoad')) {
    return {htmlStr, plainText, failText};
  }

  if (road === null) {
    htmlStr += `no road found<br>`;
    failText += ` a road, `;
    return {htmlStr, plainText, failText};
  }

  const ref = road.ref || '',
    roadDistance = Math.round(road.shortestDistance * 3.28084),
    speedlimit = Math.round(road.speedlimit * 2.23694),
    nameText = wayNaming(road),
    signText = nameText.replace(/;/g, '<br>');;

  htmlStr +=
    `<h5>Road:</h5>
<div class="d-flex flex-wrap justify-content-center">`;

  // ref may contain multiple highways, separated by `;`
  if (ref) {
    highways = ref.split(';');

    highways.forEach(highway => {
      if (highway.startsWith('US ')) {
        // a US highway
        const hwyNum = highway.replace('US ', '');
        htmlStr += `<div class="us-highway">${hwyNum}</div>`;
      } else if (highway.startsWith('CA ')) {
        // a California highway
        const hwyNum = highway.replace('CA ', '');
        htmlStr += `<div class="ca-highway">${hwyNum}</div>`;
      } else if (highway.startsWith('I ')) {
        // an interstate highway
        const hwyNum = highway.replace('I ', '');
        htmlStr += `<div class="interstate-highway">${hwyNum}</div>`;
      } else {
        // an unrecognized highway, display as a street (for now)
        htmlStr += `<div class="street-name">${signText}</div>`;
      }
    });
  } else {
    // no ref, use the wayNaming
    htmlStr += `<div class="street-name">${signText}</div>`;
  }

  if (getSetting('showSpeedlimit') && road.speedlimit !== null) {
    htmlStr +=
      `  <div class="speed-limit">${speedlimit}
  </div>`;
  }

  htmlStr += `</div>`;

  if (getSetting('speakResults')) {
    plainText =
      `The nearest road I can find is ${nameText}. `;
    if (getSetting('showSpeedlimit') && road.speedlimit !== null) {
      plainText +=
        ` Its speed limit is ${toWords(speedlimit)} ` +
        ` ${unitsInWords[road.speedUnits]}. `;
    }
  }

  return {htmlStr, plainText, failText};
}

