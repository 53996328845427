// intersection.js - dealing with intersections with a specific road

import { getDistance } from 'geolib';
import './typedefs.js';
import { getSetting } from './settings.js';
import {
  ROAD_WITH_NO_NAME,
  wayNaming,
  waysHaveSameNaming
} from './waynaming.js';

const INT_DEBUG = false;

/**
 * add all ways connected to vertex to intersections, unless the wayname is
 * identical to `roadName`
 * @param {GraphVertex} vertex
 * @param {Intersection[]} intersections
 * @param {String} roadName
 * @param {GeoPoint} fromPoint
 */
function addVertexWaysToIntersections (
  vertex,
  intersections,
  roadName,
  fromPoint
) {
  const distance = getDistance(fromPoint, vertex.node.location);
  for (const way of vertex.node.ways) {
    const wayName = wayNaming(way);
    if (wayName === roadName || wayName === ROAD_WITH_NO_NAME) {
      continue;
    }

    const name = wayNaming(way, vertex.node);
    const {node, level} = vertex;
    intersections.push({
      name,
      distance,
      level,
      node,
      way
    });

    // console.log(`added way '${name}' to intersections`);
  }
}

/**
 * get an array of intersections with road
 * @param {String} roadName
 * @param {GraphVertex|null} vertex
 * @param {GeoPoint} fromPoint
 * @param {Object<Object>} waysHash
 * @param {Number} [level]
 * @return {Array<Intersection>}
 */
function getIntersections (
  roadName,
  vertex,
  fromPoint,
  waysHash,
  level = 0
) {

  let indent = '';
  for (let i = 0; i < level; i++, indent += '    ') {}

  // debug only
  if (INT_DEBUG) {
    console.log(`${indent}getIntersections node.id: ${vertex.node.id}, ` +
      `level: ${level}`);
  }

  let intersections = [];

  if (!vertex) {
    return intersections;
  }

  // add ways connected to vertex
  addVertexWaysToIntersections(vertex, intersections, roadName, fromPoint);

  // first do a breadth search of connected vertices
  for (const thisVert of vertex.connectedVertices) {
    addVertexWaysToIntersections(thisVert, intersections, roadName, fromPoint);
  }

  // next do a depth search of connected vertices
  for (const thisVert of vertex.connectedVertices) {
    const ints = getIntersections(
      roadName,
      thisVert,
      fromPoint,
      waysHash,
      level + 1
    );
    if (ints && ints.length) {
      intersections = intersections.concat(ints);
    }
  }

  // filter out unnamed intersections
  const filteredIntersections = intersections.filter(int => {
    return int.name !== ROAD_WITH_NO_NAME;
  });

  // sort intersections by ascending level then ascending distance
  const sortedIntersections =
    filteredIntersections.sort((intA, intB) => {
      let levelDelta = intA.level - intB.level,
        distanceDelta = intA.distance - intB.distance;

      // TERRY force to distance only search
      levelDelta = 0;

      if (levelDelta !== 0) {return levelDelta;}
      return distanceDelta;
    });

  // return sortedIntersections;

  // remove duplicate intersections
  const intSet = new Set();
  return sortedIntersections.reduce(
    (uniques, int) => {
      // unique intersection has unique name and distance
      const key = JSON.stringify({
          name: int.name,
          distance: int.distance
        });
      if (intSet.has(key)) {return uniques;}
      intSet.add(key);
      uniques.push(int);
      return uniques;
    }, []);
}

/**
 * get intersection information to display
 * @param {Intersection[]|null} intersections
 * @param {Way|null} closestRoad
 * @return {{failText: string, plainText: string, htmlStr: string}}
 */
function intersectionsToDisplay (
  intersections,
  closestRoad
) {
  let htmlStr = '', htmlContent = '', plainText = '', failText = '',
    intCount = 0;

  if (!getSetting('showIntersections')) {
    return {htmlStr, plainText, failText};
  }

  if (!intersections || intersections.length === 0) {
    htmlStr += `no intersection found<br>`;
    failText = ', an intersection, ';
    return {htmlStr, plainText, failText};
  }

  // evaulate each intersection
  for (const intersection of intersections) {

    if (!intersection.node || !intersection.way) {
      continue;
    }

    const node = intersection.node,
      way = intersection.way;
    let distanceFT = Math.round(intersection.distance * 3.28084 / 50) * 50,
      distanceMiles = Math.round(distanceFT / 5280 * 10) / 10,
      distance,
      units,
      distanceText;
    if (distanceFT >= 1000) {
      // report big distances in miles, rounded to tenths
      distance = distanceMiles;
      units = (distance === 1.0) ? 'MILE' : 'MILES';
    } else {
      // report small distances in feet, rounded to 50 feet
      distance = distanceFT;
      units = 'FEET';
    }
    distanceText = `${distance} ${units}`;

    let name = wayNaming(way, node);

    // to allow display of separate lines, convert semicolons to `<br>`
    const nameHTML = name.replace(/;/g, '<br>');

    htmlContent +=
      `  <div class="intersection">
    <p>${nameHTML}</p>
    <p>${distance} <span class="units">${units}</span></p>
  </div>`;

    if (getSetting('speakResults')) {
      plainText += intCount === 0 ?
        `An intersection is nearby: ${name}, about ${distanceText} away.`
        : ` Also, ${name}, about ${distanceText} away.`;
    }
    intCount++;

    if (intCount >= 2) {break;}
  }

  if (htmlContent) {
    htmlStr +=
      `<h5>Intersection${intCount > 1 ? 's' : ''}:</h5>
     <div class="d-flex justify-content-center">${htmlContent}</div>`;
  }

  return {htmlStr, plainText, failText};
}

export {
  getIntersections,
  intersectionsToDisplay
};
