// main.js - main script for `what-road-am-i-on`

import './js/typedefs.js';
import './js/pageelements.js';
import './js/findlocationbutton.js';
import './js/showtextbutton.js';
import './js/showmapbutton.js';
import './js/settingsbutton.js';
import { appState } from './js/state.js';
import { initMapPanel } from './js/mappanel.js';
import { displayLanding } from './js/landing.js';
import { versionCheck } from './js/version.js';
import { initTextPanel } from './js/textpanel.js';
import { initSettingsPanel } from './js/settingspanel.js';
import { isStandAloneApp } from './js/iosdetection.js';
import { clearCache } from './js/sessioncache.js';

// check for version change whenever window gets focus
// (this will enable updating of PWAs)
window.addEventListener('focus', function (ev) {
  if (ev.target !== window) {return;}

  // clear cache periodically when in standalone mode
  // to prevent using up too much storage
  if (isStandAloneApp()) {
    const lastCacheClearTime = Number(appState.lastCacheClearTime);
    const now = Date.now();
    if (now - lastCacheClearTime < 1000 * 60 * 30) {return;}
    console.log('clearing cache for standalone iOS app');
    clearCache();
    appState.lastCacheClearTime = now;
  }

  // compare versions, show modal if they are different
  versionCheck('focus');
});

// initalize upon window load
window.addEventListener('load', function () {
  appState.init();
  initSettingsPanel();
  initTextPanel();
  initMapPanel();
  displayLanding();
  versionCheck('load');
});
