// state.js - handle whereami settings

const DEFAULT_PANEL = 'map',
  DEFAULT_ZOOM = 7,
  DEFAULT_CENTER = {latitude: 39.0533, longitude: -105.8423},
  DEFAULT_TARGET = null;

const _state = {
  panel: DEFAULT_PANEL,
    mapCenter: DEFAULT_CENTER,
    mapTarget: DEFAULT_TARGET,
    zoomlevel: DEFAULT_ZOOM,
    followMapLocation: false,
    firstUsage: true,
    searching: false,
    machineMapInteraction: false,
    lastMapInteractionTime: 0,
    lastCacheClearTime: 0,
    userHasSeenGeoModal: false,
}


const appState = {

  get panel () {return _state.panel;},
  get mapCenter () {return _state.mapCenter;},
  get mapTarget () {return _state.mapTarget;},
  get zoomlevel () {return Number(_state.zoomlevel);},
  get followMapLocation () {return _state.followMapLocation;},
  get firstUsage () {return _state.firstUsage;},
  get searching () {return _state.searching;},
  get machineMapInteraction () {return _state.machineMapInteraction;},
  get lastMapInteractionTime () {return _state.lastMapInteractionTime;},
  get lastCacheClearTime () {return _state.lastCacheClearTime;},
  get userHasSeenGeoModal () {return _state.userHasSeenGeoModal;},

  set panel (val) {
    _state.panel = val;
    localStorage.setItem('_whereami_state', JSON.stringify(_state));
  },
  set mapCenter (val) {
    _state.mapCenter = val;
    localStorage.setItem('_whereami_state', JSON.stringify(_state));
  },
  set mapTarget (val) {
    _state.mapTarget = val;
    localStorage.setItem('_whereami_state', JSON.stringify(_state));
  },
  set zoomlevel (val) {
    _state.zoomlevel = val;
    localStorage.setItem('_whereami_state', JSON.stringify(_state));
  },
  set followMapLocation (val) {
    _state.followMapLocation = val;
    localStorage.setItem('_whereami_state', JSON.stringify(_state));
  },
  set firstUsage (val) {
    _state.firstUsage = val;
    localStorage.setItem('_whereami_state', JSON.stringify(_state));
  },
  set searching (val) {
    _state.searching = val;
    localStorage.setItem('_whereami_state', JSON.stringify(_state));
  },
  set machineMapInteraction (val) {
    _state.machineMapInteraction = val;
    localStorage.setItem('_whereami_state', JSON.stringify(_state));
  },
  set lastMapInteractionTime (val) {
    _state.lastMapInteractionTime = val;
    localStorage.setItem('_whereami_state', JSON.stringify(_state));
  },
  set lastCacheClearTime (val) {
    _state.lastCacheClearTime = val;
    localStorage.setItem('_whereami_state', JSON.stringify(_state));
  },
  set userHasSeenGeoModal (val) {
    _state.userHasSeenGeoModal = val;
    localStorage.setItem('_whereami_state', JSON.stringify(_state));
  },

  init: function () {
    // console.log('appState.init()');
    const storedStateJSON = localStorage.getItem('_whereami_state');
    if (storedStateJSON) {
      Object.assign(_state, JSON.parse(storedStateJSON));
    } else {
      localStorage.setItem('_whereami_state', JSON.stringify(_state));
    }
  },

};

export {
  appState
};
