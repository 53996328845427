// findlocationbutton.js - handle user clicks on buttons

// handle click on "find location" nav button
import * as el from './pageelements.js';
import { appState } from './state.js';
import { setTargetAndMapCenterToUsertLocation } from './setlocation.js';
import { searchAtLocation } from './searchlocation.js';
import { showAndSpeakResult } from './showandspeakresult.js';
import { clearMap } from './map.js';
import { displayActivePanel, getPanelElements, hidePanels } from './panels.js';
import { clickDispatcher } from './clickdispatcher.js';
import { addTooltipToElement } from './tutorial.js';
import { setEraseMapButtonOn } from './mappanel.js';
import { showResultOnMap } from './showresultonmap.js';

function handleFindLocation () {
  el.deactivateNavButtons();
  el.btnNavFindLocation.classList.add('active');

  appState.searching = true;

  // hide panels, clear map and text panels, show spinner
  hidePanels();
  clearMap(false);
  const {element: textPanel} = getPanelElements('text');
  textPanel.innerHTML = `<p>(no current search)</p>`;
  el.divSpinner.classList.remove('d-none');

  new Promise(resolve => { resolve(); })

    .then(() => {
      // if a center position exists, use it
      const mapCenter = appState.mapCenter;
      if (mapCenter) { return mapCenter;}

      // no map center, use the device's current location
      return setTargetAndMapCenterToUsertLocation();
    })

    .then(coords => {
      return searchAtLocation(coords);
    })

    .then(({
             closestRoad,
             roadSegments,
             closestBuilding,
             closestPlace,
             intersections
           }) => {
      // update text panel and speak result
      showAndSpeakResult(
        closestRoad,
        closestBuilding,
        closestPlace,
        intersections
      );

      console.log(`handleFindLocation results:`, {
        closestRoad,
        closestBuilding,
        closestPlace,
        intersections
      });

      // update map
      showResultOnMap(
        closestRoad,
        roadSegments,
        closestBuilding,
        closestPlace,
        intersections
      );

      // turn "erase map" button on
      setEraseMapButtonOn();

      // hide spinner
      el.divSpinner.classList.add('d-none');

      // "click" on active panel
      displayActivePanel();

      appState.searching = false;
      appState.lastMapInteractionTime =  Date.now();

    });
}

el.btnNavFindLocation.handler = handleFindLocation;
el.btnNavFindLocation.addEventListener('click', clickDispatcher);

// add tooltip for tutorial mode
addTooltipToElement(el.btnNavFindLocation, `
<div class="pb-3 text-start">
  <p>Search for road, street address, etc., near the current map center.</p>
  <p>The map center is identified with crosshairs:</p>
  <img class="img-tutorial"
    src="imgs/crosshairs-example.png"
    alt="map crosshairs example">
</div>`
);

// add speak text
el.btnNavFindLocation.dataset.speakText = 'search';
