// geolocation.js - get geo coordinates

let hasPriorGeolocationSuccess = false;

/**
 * get the current device position
 * @return {Promise<GeolocationCoordinates>}
 */
function getCurrentGeoCoords () {

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(pos => {
      resolve(pos);
    }, err => {
      reject(err);
    });
  })
    /** @type {GeolocationPosition} pos **/
    .then(pos => {
      // debug.log('getCurrentGeoCoords returning pos.coords:', pos.coords);
      hasPriorGeolocationSuccess = true;
      return pos.coords;
    })
    .catch(err => {
      console.error(`getCurrentGeoCoords err:`, err);
      throw err;
    });
}

/**
 * get current location's GeoPoint
 * @note coords rounded to 5 decimal places (~ 5 feet)
 * @return {Promise<GeoPoint|null>}
 */
function getCurrentGeoPoint () {
  return getCurrentGeoCoords()
    .then(coords => {
      const latitude = Number((coords.latitude).toFixed(5)),
        longitude = Number((coords.longitude).toFixed(5));
      return {
        latitude,
        longitude
      };
    })
    .catch(err => {
      console.error('getCurrentGeoPoint err:', err);
      return null;
    });
}

export {
  getCurrentGeoPoint,
};
