// panels.js - manage the display of panels

import { appState } from './state.js';

const _panel = {};

/**
 *
 * @param {string} panelName
 * @param {String} buttonId
 * @param {Function<void>} showFn
 * @param {HTMLDivElement} element
 */
function addPanel (
  panelName,
  buttonId,
  showFn,
  element) {
  // console.log('addPanel()');
  _panel[panelName] = {
    buttonId,
    show: showFn,
    element
  }
}

/**
 * return element associated with panel name
 * @param {String} name
 * @return {{button: HTMLButtonElement, element: HTMLDivElement}}
 */
function getPanelElements (name) {
  if (! name in _panel) {
    throw new Error(`_panel name '${name}' unknown`);
  }
  let {buttonId, show, element} = _panel[name];
  /** @type {HTMLButtonElement} **/
  const button = document.querySelector('#' + buttonId);
  /** @type {HTMLDivElement} **/
  element = element ||
    document.querySelector('#' + button.dataset.panelId);
  return {
    button,
    show,
    element
  };
}

/**
 * @description hide all the panels
 */
function hidePanels () {
  let panels = document.querySelectorAll('.whereami-panel');
  panels.forEach(panel => {
    panel.classList.add('d-none');
  });
}

/**
 * display the named panel
 * @param {String} panelName 'text' || 'map' || 'settings'
 */
function displayPanel (panelName) {

  if (! panelName in _panel) {
    throw new Error(`displayPanel: no panel found named '${panelName}'`);
  }

  _panel[panelName].show();
}

/**
 * display the currently active panel
 */
function displayActivePanel () {
  const activeName = appState.panel;
  displayPanel(activeName);
}

export {
  addPanel,
  getPanelElements,
  hidePanels,
  displayPanel,
  displayActivePanel
};
