// form and query names of ways (roads)

import './typedefs.js';

const ROAD_WITH_NO_NAME = 'an unnamed road';

/**
 * true if way contains some sort of a name
 * @param {Way} way
 * @return {boolean}
 */
function wayHasSomeSortOfName (way) {
  return Boolean(way.name || way.ref || way.destination);
}

/**
 * name to associate with a way
 * @param {Way} [way]
 * @param {Object} [node] - node may have a usefel `ref` (a highway exit num)
 * @return {string}
 */
function wayNaming (way, node) {

  let nameStr = '',
    nodeName = '';

  if (!way) {return '';}

  if (way && !way.ref && !way.name && !way.destination) {
    return ROAD_WITH_NO_NAME;
  }

  nameStr += way.ref || '';
  nameStr += (nameStr.length ? '; ' : '') + (way.name || '');
  if (way && way.destination) {
    nameStr += (nameStr.length ? '; ' : '') +
      ('Destination; ' + way.destination || '');
  }
  if (node && node.highway === 'motorway_junction' && node.ref) {
    nodeName = `Exit ${node.ref}; `;
  }
  return nodeName + nameStr;
}

/**
 * true if both ways have the same naming (name and/or ref)
 * @param {Way} wayA
 * @param {Way} wayB
 * @return {boolean}
 */
function waysHaveSameNaming (wayA, wayB) {
  return wayNaming(wayA) === wayNaming(wayB);
  // return (!wayA.ref && !wayB.ref && !wayA.name && !wayB.name) ||
  //   (wayA.ref && wayA.ref === wayB.ref) ||
  //   (wayA.name && wayA.name === wayB.name);
}

export {
  wayHasSomeSortOfName,
  wayNaming,
  waysHaveSameNaming,
  ROAD_WITH_NO_NAME
}
