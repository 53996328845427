// connection graph of nodes as vertices and ways as edges


import { wayNaming, waysHaveSameNaming } from './waynaming.js';

const GRAPH_DEBUG = false;

/**
 * a single vertex of the connection graph
 * @typedef {Object} GraphVertex
 * @property {Number} level - depth of vertex in graph
 * @property {Node} node - this node object
 * @property {Array<GraphVertex>} connectedVertices
 * @property {String} [startWayNaming] only exists for the root node
 * @property {Boolean} [hasIntersection]
 * @property {String} [intersectionName]
 */


/**
 * form graph of connected Nodes
 * @param {Number} nodeID - node to form root of (sub)graph
 * @param {Object} startingWay - way where the graph starts
 * @param {Object<Object>} nodesHash
 * @param {Object<Object>} waysHash
 * @param {Set} seenSet
 * @param {number} [level]
 * @return {GraphVertex|null}
 */
function formConnectionGraph (
  nodeID,
  startingWay,
  nodesHash,
  waysHash,
  seenSet = new Set(),
  level = 0
) {

  if (!startingWay || !nodesHash || !waysHash) { return null; }

  if (level === 0) {
    console.log(`formConnectionGraph startingWay.name: '${startingWay.name}'`);
    nodeID = startingWay.nodes[startingWay.iLineShortestDistance];
  }

  const thisNode = nodesHash[nodeID];
  let indent = '';
  for (let i = 0; i < level; i++, indent += '    ') {}

  // debug only
  if (GRAPH_DEBUG) {
    console.log(
      `${indent}formConnectionGraph nodeID: ${nodeID}, ` +
      `level: ${level}, ref: ${thisNode.ref || ''}`);
  }

  // to prevent cycle, use `seenSet` to track nodes that have been seen
  if (seenSet.has(nodeID)) {
    if (GRAPH_DEBUG) {
      console.log(`${indent}  node ${nodeID} has been seen, skipping`);
    }
    return null;
  }
  seenSet.add(nodeID);

  /** @type {GraphVertex} returnData **/
  let returnData = {
    level,
    node: thisNode,
    connectedVertices: []
  };
  if (level === 0) {
    returnData.startWayNaming = wayNaming(startingWay, thisNode);
  }

  // form array of new nodes that connect directly to nodeID
  let connectedVertices = [];
  thisNode.ways.forEach(way => {

    // ignore way if it has a different name than startingWay
    if (!waysHaveSameNaming(way, startingWay)) {
      returnData.hasIntersection = true;
      return;
    }

    // evaluate each node connected to way
    way.nodes.forEach(nodeID => {
      const node = nodesHash[nodeID];

      // skip node if it has less than two ways
      if (!node || !node.ways || node.ways.length < 2) {
        return;
      }

      // skip node if it has been seen
      if (seenSet.has(nodeID)) {return;}

      // add vertex to connectedVertices
      const subGraph = formConnectionGraph(
        nodeID,
        startingWay,
        nodesHash,
        waysHash,
        seenSet,
        level + 1
      );
      if (subGraph) {connectedVertices.push(subGraph);}
    });

  });

  if (connectedVertices.length > 0) {
    returnData.connectedVertices = connectedVertices;
  }

  return returnData;
}

export {
  formConnectionGraph
};
