// overpass-query.js - form an Overpass query string

import { getSetting } from './settings.js';
import { cacheQueryResult, getCachedQueryResponse } from './sessioncache.js';

// Overpass services
const overpassURL = 'https://lz4.overpass-api.de/api/interpreter';
// const overpassURL = 'https://z.overpass-api.de/api/interpreter';
// const overpassURL = 'https://overpass.openstreetmap.fr/api/interpreter';
// const overpassURL = 'https://overpass.kumi.systems/api/interpreter';

// radius of search circles
const aroundRoads = 100,
  aroundBuildings = 50,
  aroundCity = 8000;
const highwayValues = [
  'motorway',
  'trunk',
  'primary',
  'secondary',
  'tertiary',
  'unclassified',
  'residential',
  'living_street',
  'motorway_link'
].join('|');
const highwayGrep = `highway~"^(${highwayValues})$"`;

/**
 * form a "search for nearby items" overapass query
 * @param {GeoPoint} searchPoint
 * @return {string} overpass query
 */
function formNearbyQuery (searchPoint) {
  const {latitude, longitude} = searchPoint;
  const limitsRoad = `around:${aroundRoads},${latitude},${longitude}`,
    limitsBuilding = `around:${aroundBuildings},${latitude},${longitude}`,
    limitsCity = `around:${aroundCity},${latitude},${longitude}`,
    showRoad = getSetting('showRoad'),
    showAddress = getSetting('showAddress'),
    showCenter = getSetting('showCenter');

  let query =
    `// searching location ${latitude},${longitude}\n`;
  if (showRoad) {
    query +=
      `// for roads within ${aroundRoads} meters\n`;
  }
  if (showAddress) {
    query +=
      `// for places withn ${aroundBuildings} meters\n`;
  }
  if (showCenter) {
    query +=
      `// for city centers within ${aroundCity} meters\n`;
  }
  query +=
    `[out:json][timeout:15];
(\n`;
  if (showRoad) {
    query +=
      `  way(${limitsRoad})[${highwayGrep}];\n`;
  }
  if (showAddress) {
    query +=
      `  way(${limitsBuilding})[building]['addr:street']['addr:housenumber'];\n`;
  }
  if (showCenter) {
    query +=
      `  node(${limitsCity})[place~'^(city|town)'];\n`;
  }
  query +=
    `);
out geom;\n`;

console.log(`formNearbyQuery query:\n${query}`);

  return query;
}

/**
 * perform an Overpass query
 * @param {String} query
 * @return {Promise<unknown>|Promise<any>}
 */
function performOverpassQuery (query) {
  // console.log(`performOverpassQuery()`);

  // use cached response if it exists
  const responseCached = getCachedQueryResponse(query);
  if (responseCached) {
    // console.log('  performOverpassQuery found cached response');
    return new Promise(resolve => {resolve(responseCached);});
  }

  // query the API
  return fetch(overpassURL, {
    method: 'POST',
    body: query
  })
    .then(response => {
      const status = response.status;
      if (status !== 200) {
        throw new Error(
          `I'm having trouble talking with the server (status ${status}).`
        );
      }

      return response.json();
    })

    .then(responseJS => {
      // cache js response if possible
      cacheQueryResult(query, responseJS);

      return responseJS;
    })

    .catch(err => {
      console.error('performOverpassQuery error:', err);
      throw err;
    });
}

/**
 * query to find ways that connect with thisWay, ignoring ways with matching
 * names
 * @param {Way|null} thisWay
 * @return {string}
 */
function formCrossStreetQuery (thisWay) {

  if (!thisWay) {return '';}

  const wayID = thisWay.id;

  return `// search for highways that connect with way(${wayID})
 [out:json];
 way(${wayID});
 complete(5) {
   ._;
   node(w);
   way(bn)[${highwayGrep}];
 }
 // also output the nodes
 (
   ._;
   node(w);
 );
 out geom;`;
}

/**
 * overpass API fetch nearby searchPoint
 * @param {{latitude: number, longitude: number}} searchPoint
 * @return {Promise<Object>} API data as a JS object
 */
function fetchOverpassNearby (searchPoint) {
  // console.log('fetchOverpassNearby() searchPoint:', searchPoint);

  const query = formNearbyQuery(searchPoint);
  return performOverpassQuery(query);
}

/**
 * overpass API fetch cross street data
 * @param {Way|null} way
 * @return {Promise<Object|null>} API data as a JS object
 */
function fetchOverpassCrossing (way) {
// console.log('fetchOverpassCrossing() way:', way);

  if (!way || !getSetting('showIntersections')) {
    return new Promise(resolve => resolve(null));
  }

  const query = formCrossStreetQuery(way);
  return performOverpassQuery(query);
}

export {
  fetchOverpassNearby,
  fetchOverpassCrossing
};
