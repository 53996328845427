// type definitions for this app

/**
 * a geolocation point
 * @typedef {Object} GeoPoint
 * @property {Number} latitude
 * @property {Number} longitude
 */


/**
 * bounds of an Overpass API way
 * @typedef {Object} WayBounds
 * @property {Number} minlat
 * @property {Number} minlon
 * @property {Number} maxlat
 * @property {Number} maxlon
 */

/**
 * @typedef {Object} Way
 * @property {Number} id
 * @property {WayBounds} bounds
 * @property {Array<GeoPoint>} geometry
 * @property {Array<Number>} nodes
 * @property {String} [highway]
 * @property {String} [maxspeed]
 * @property {String} [name]
 * @property {String} [ref]
 * @property {String} [destination]
 *
 * our added properties:
 * @property {Array<number>} lineDistances
 * @property {Number} [shortestDistance]
 * @property {Number} [iLineShortestDistance]
 * @property {Number} [speedlimit] - derived from `maxspeed` (m/s)
 * @property {String} [speedUnits] - dreived from `maxspeed` ('kph'|'mph')
 */

/**
 * @typedef {Object} Node
 * @property {Number} id
 * @property {GeoPoint} location
 * @property {String} [highway]
 * @property {String} [ref]
 * @property {String} [name]
 * @property {String} [place] - 'city', 'town', ...
 *
 * our added properties:
 * @property {Array<Way>} [ways]
 */

/**
 * Building, based on Way
 * @typedef {Object} Building
 * @property {Number} id
 * @property {Number} housenumber
 * @property {String} street
 */

/**
 * an intersection found by getIntersections()
 * @typedef {Object} Intersection
 * @property {Node} node
 * @property {Way} way
 * @property {String} name
 * @property {Number} distance
 * @property {Number} level
 */

