// mappanel.js - initalize and control the map

import { getCurrentGeoPoint } from './geolocation.js';
import {
  addMapButton,
  addMapEventListener,
  addTarget, clearMap,
  createMap, getMapMaxZoom, getMapMinZoom,
  moveMapCenter, zoomMap
} from './map.js';
import { appState } from './state.js';
import * as el from './pageelements.js';
import { setLocationTracking } from './tracking.js';
import { addTooltipToElement } from './tutorial.js';
import { clickDispatcher } from './clickdispatcher.js';
import { handleShowMapButton } from './showmapbutton.js';
import { addPanel } from './panels.js';
import { getGeoPointWithDialog } from './geolocationmodal.js';

const _mapBtns = {};

/**
 * handle user click on "move to my location" button
 */
function handleMapMoveToUserLocation () {

  // zoom in if necessary
  let zoomlevel = Math.max(appState.zoomlevel, 16);

  // get the current device location
  getGeoPointWithDialog()

    .then(coords => {
      if (!coords) {return;}
      const {latitude, longitude} = coords;

      // add target at location
      const location = {latitude, longitude};
      appState.machineMapInteraction=  true;
      addTarget(location);
      moveMapCenter(location, zoomlevel);
      appState.machineMapInteraction = false;
      appState.mapCenter = location;
      appState.mapTarget = location;
      appState.zoomlevel = zoomlevel;
    });
}

/**
 * turn "follow my location" on/off
 */
function handleMapTrackingSwitch () {

  const trackingSwitch = _mapBtns.trackingSwitch;
  let followMapLocation = !appState.followMapLocation;
  appState.followMapLocation = followMapLocation;
  appState.lastMapInteractionTime = 0;

  // if ON, move immediately
  if (followMapLocation) {
    handleMapMoveToUserLocation();
  }

  // console.log(`handleMapTrackingSwitch trackingSwitch:`, trackingSwitch);
}

// this is called when the user has manually interacted with the map
function _mapInteractionListener (ev) {
  // console.log(`_mapInteractionListener() ev.type: '${ev.type}', ev:`, ev);

  // ignore if machine has moved the map
  if (appState.machineMapInteraction) {
    // console.log('_mapInteractionListener machine is doing the interaction, ignored');
    return;
  }

  // log the time of this move
  const now = Date.now();
  appState.lastMapInteractionTime =  now;
  // console.log('  _mapInteractionListener setting lastMapInteractionTime');
}

/**
 * handle click of erase map button
 */
function handleEraseMap () {
  // erase everything except for location target
  clearMap(false);
  // set button to "off"
  const eraseBtn = _mapBtns.eraseBtn;
  eraseBtn.classList.remove('on');
  eraseBtn.classList.add('btn-disabled');
}

function setEraseMapButtonOn () {
  const eraseBtn = _mapBtns.eraseBtn;
  eraseBtn.classList.add('on');
  eraseBtn.classList.remove('btn-disabled');
}

/**
 * handle click on "zoom in" button
 */
function handleMapZoomIn () {
  const zoomInBtn = _mapBtns.zoomInBtn,
    zoomOutBtn = _mapBtns.zoomOutBtn,
    maxZoomlevel = getMapMaxZoom();
  let zoomlevel = appState.zoomlevel;

  if (zoomlevel === maxZoomlevel) {return;}

  appState.machineMapInteraction = true;
  zoomMap(++zoomlevel);
  appState.machineMapInteraction = false;
  appState.zoomlevel = zoomlevel;

  if (zoomlevel === maxZoomlevel) {
    zoomInBtn.classList.add('btn-disabled');
  }

  zoomOutBtn.classList.remove('btn-disabled');
}

/**
 * handle click on "zoom out" button
 */
function handleMapZoomOut () {
  const zoomOutBtn = _mapBtns.zoomOutBtn,
    zoomInBtn = _mapBtns.zoomInBtn,
    minZoomlevel = getMapMinZoom();
  let zoomlevel = appState.zoomlevel;

  if (zoomlevel <= minZoomlevel) {return;}

  appState.machineMapInteraction = true;
  zoomMap(--zoomlevel);
  appState.machineMapInteraction =  false;
  appState.zoomlevel = zoomlevel;

  if (zoomlevel === minZoomlevel) {
    zoomOutBtn.classList.add('btn-disabled');
  }

  zoomInBtn.classList.remove('btn-disabled');
}

/**
 * initialize the map
 */
function initMapPanel () {

  // console.log('initMapPanel()');

  addPanel(
    'map',
    'btn-nav-show-map',
    handleShowMapButton,
    el.divMap
  );

  const mapCenter = appState.mapCenter;
  createMap(el.divMap, mapCenter);

  // // "move map to user location" button
  // const moveToUserLocation = addMapButton(
  //   'fal fa-location-arrow',
  //   function () {},
  //   undefined,
  //   'topleft');
  // addTooltipToElement(moveToUserLocation, `
  //   <div class="pb-3 text-start">
  //     <p>Move the map center to your current location.</p>
  //     <p>The map center is marked with crosshairs:</p>
  //     <img class="img-tutorial"
  //       src="imgs/crosshairs-example.png"
  //       alt="map crosshairs example">
  //   </div>`
  // );
  // moveToUserLocation.dataset.speakText = 'move map to my location';
  // moveToUserLocation.handler = handleMapMoveToUserLocation;
  // moveToUserLocation.addEventListener('click', clickDispatcher);
  // moveToUserLocation.classList.add('btn-generic');
  // _mapBtns.moveToUserLocation = moveToUserLocation;

  // zoom in
  const zoomInBtn = addMapButton(
    'fal fa-plus',
    function () {},
    undefined,
    'topleft'
  );
  zoomInBtn.dataset.speakText = 'zoom in';
  zoomInBtn.handler = handleMapZoomIn;
  zoomInBtn.addEventListener('click', clickDispatcher);
  zoomInBtn.classList.add('btn-generic');
  if (appState.zoomlevel >= getMapMaxZoom()) {
    zoomInBtn.classList.add('btn-disabled');
  }
  _mapBtns.zoomInBtn = zoomInBtn;

  // zoom out
  const zoomOutBtn = addMapButton(
    'fal fa-minus',
    function () {},
    undefined,
    'topleft'
  );
  zoomOutBtn.dataset.speakText = 'zoom out';
  zoomOutBtn.handler = handleMapZoomOut;
  zoomOutBtn.addEventListener('click', clickDispatcher);
  zoomOutBtn.classList.add('btn-generic');
  if (appState.zoomlevel === 1) {
    zoomOutBtn.classList.add('btn-disabled');
  }
  _mapBtns.zoomOutBtn = zoomOutBtn;

  // "location tracking on/off" button
  const trackingSwitch = addMapButton(
    'fal fa-location-arrow',
    function () {},
    undefined,
    'topleft'
  );
  addTooltipToElement(trackingSwitch, `
    <div class="pb-3 text-start">
      <p>Continually move the map to track your current location.</p>
      <p>Click this button to turn tracking on and off (button is green when on).</p>
      <img class="img-tutorial"
        src="imgs/map-tracking-example.png"
        alt="whereami map tracking example with circle and crosshairs">
    </div>`
  );
  trackingSwitch.dataset.speakText = 'follow my location';
  trackingSwitch.handler = handleMapTrackingSwitch;
  trackingSwitch.addEventListener('click', clickDispatcher);
  trackingSwitch.classList.add('btn-generic');

  // "erase map" button
  const eraseBtn = addMapButton(
    'fal fa-eraser',
    function () {},
    undefined,
    'topleft'
  );
  addTooltipToElement(eraseBtn, `
<div class="pb-3 text-start">
  <p>Erase search results from the map</p>
</div>`
  );
  eraseBtn.dataset.speakText = 'erase map';
  eraseBtn.handler = handleEraseMap;
  eraseBtn.addEventListener('click', clickDispatcher);
  eraseBtn.classList.add('btn-generic');
  eraseBtn.classList.add('btn-disabled');
  _mapBtns.eraseBtn = eraseBtn;

  // force tracking switch to 'off' at load time (prevents jarring
  // "get user tracking permission" dialog as first thing user sees)
  trackingSwitch.switchState = false;
  appState.followMapLocation = false;
  trackingSwitch.classList.remove('on');
  _mapBtns.trackingSwitch = trackingSwitch;

  // add a listener for map moves
  addMapEventListener('movestart', _mapInteractionListener);

  // add our custom styling to controls
  const leafletBars = document.querySelectorAll(
    '.leaflet-touch .leaflet-bar'
  );
  leafletBars.forEach(el => {
    el.classList.add('wai-map-control');
  });

  // move control bar from left side to right side of map
  const divControl = document.querySelector(
    '.leaflet-top.leaflet-left'
  );
  if (divControl) {
    divControl.classList.add('leaflet-right');
    divControl.classList.remove('leaflet-left');
  }

}

export {
  handleMapMoveToUserLocation,
  setEraseMapButtonOn,
  initMapPanel
};
