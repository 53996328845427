// numtowords.js - utility functions to convert numbers to speakable words

import { toWords } from '../convert/numberToWords.es6.js';

// numeric digits in spoken word
const digits = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
];

/**
 * @description convert each digit in a number to words
 * @example 3101 -> 'three one oh one'
 * @param {number} num
 * @return {string}
 */
function convertDigitsToWords (num) {
  return String(num)
    .split('')
    .map((num) => digits[num])
    .join(' ');
}

export { toWords, convertDigitsToWords };

