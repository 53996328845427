// the whereami page elements

const divMap = document.querySelector('#div-map');
const divSpinner = document.getElementById('div-spinner');
const latitude = document.querySelector('#latitude');
const longitude = document.querySelector('#longitude');
const divLanding = document.querySelector('#div-landing');
const btnLanding = document.querySelector('#btn-landing');
const divNav = document.querySelector('#div-nav');
const btnNavFindLocation = document.querySelector('#btn-nav-find-location');
const btnNavShowText = document.querySelector('#btn-nav-show-text');
const btnNavShowMap = document.querySelector('#btn-nav-show-map');
const btnNavSettings = document.querySelector('#btn-nav-settings');

const navButtons = [
  btnNavFindLocation,
  btnNavShowText,
  btnNavShowMap,
  btnNavSettings
];

/**
 * @description remove 'active' class from all nav buttons
 */
function deactivateNavButtons () {
  navButtons.forEach(btn => {
    btn.classList.remove('active');
  });
}

export {
  divMap,
  divSpinner,
  latitude,
  longitude,
  divLanding,
  btnLanding,
  divNav,
  btnNavFindLocation,
  btnNavShowText,
  btnNavShowMap,
  btnNavSettings,
  deactivateNavButtons
};
