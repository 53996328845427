// detect if this is an iOS device in full-screen (save to home screen) mode
// ref: https://stackoverflow.com/a/7016526/3113485

/**
 * true if this is an iOS device in standalone mode (save to home screen)
 * @return {false|*|boolean}
 */
function isStandAloneApp () {
  return 'standalone' in navigator && // navigator.standalone exists
    navigator.standalone && // and is true
    (/iphone|ipod|ipad/gi).test(navigator.platform) && // and is iOS device
    (/Safari/i).test(navigator.appVersion); // and is Safari
}

/**
 * true if this is an iOS device
 * @return {boolean}
 */
function isIosDevice () {
  return (/iphone|ipod|ipad/gi).test(navigator.platform);
}

export {
  isIosDevice,
  isStandAloneApp
};
