// showandspeakresult.js - show and tell user the resutls of a search

import { roadInfoToDisplay } from './roads.js';
import { intersectionsToDisplay } from './intersection.js';
import { buildingInfoToDisplay } from './buildings.js';
import { placeInfoToDisplay } from './places.js';
import { speak } from './speech.js';
import { getSetting } from './settings.js';
import * as el from './pageelements.js';
import { getPanelElements } from './panels.js';

/**
 * show and speak the results of the search
 * @param {Way|null} closestRoad
 * @param {Building|null} closestBuilding
 * @param {Node|null} closestPlace
 * @param {Intersection[]|null} intersections
 */
function showAndSpeakResult (
                               closestRoad,
                               closestBuilding,
                               closestPlace,
                               intersections
                             ) {
  const {element: textPanel} = getPanelElements('text');

  let textToSpeak = '';
  let failTextToSpeak = '';
  textPanel.innerHTML = '';

  // display the found road (if any)
  const roadInfo = roadInfoToDisplay(closestRoad);
  textPanel.innerHTML += roadInfo.htmlStr;
  textToSpeak += ` ${roadInfo.plainText}`;
  failTextToSpeak += roadInfo.failText;

  // display the found building (if any)
  const buildingInfo = buildingInfoToDisplay(closestBuilding);
  textPanel.innerHTML += buildingInfo.htmlStr;
  textToSpeak += ` ${buildingInfo.plainText}`;
  failTextToSpeak += buildingInfo.failText;

  // display a nearby intersection
  const intInfo = intersectionsToDisplay(intersections, closestRoad);
  textPanel.innerHTML += intInfo.htmlStr;
  textToSpeak += ` ${intInfo.plainText}`;
  failTextToSpeak += intInfo.failText;

  // display the found city/town (if any)
  const placeInfo = placeInfoToDisplay(closestPlace);
  textPanel.innerHTML += placeInfo.htmlStr;
  textToSpeak += ` ${placeInfo.plainText}`;
  failTextToSpeak += placeInfo.failText;

  // speak the info
  if (getSetting('speakResults') && textToSpeak) {
    // console.log(`textToSpeak: '${textToSpeak}'`);
    speak(textToSpeak);
  }
  if (getSetting('speakResults') && failTextToSpeak) {
    failTextToSpeak =
      ` I didn't find ... ${failTextToSpeak}.`;
    // console.log(`failTextToSpeak: '${failTextToSpeak}'`);
    speak(failTextToSpeak);
  }

  // // show result panel
  // textPanel.classList.remove('d-none');

  // // scroll window to top of result
  // const resultRect = textPanel.getBoundingClientRect();

  // console.log(`showAndSpeakResult scroll to resultRect:`, resultRect);

  // window.scroll({
  //   top: resultRect.top,
  //   left: resultRect.left,
  //   behavior: 'smooth'
  // });

}

export { showAndSpeakResult };
