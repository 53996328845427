import { hideActiveTooltip, setActiveTooltip } from './tutorial.js';
import { getSetting } from './settings.js';
import { Tooltip } from 'bootstrap';
import { speak } from './speech.js';

/**
 * dispatch a click on a button
 * @param {MouseEvent} ev
 */
function clickDispatcher (ev) {
  const btn = this;
  const tooltip = Tooltip.getInstance(btn);
  const isSwitch = typeof btn.switchState !== 'undefined';
  const speakCommands = getSetting('speakCommands');

  // console.log('clickDispatcher btn:', btn);

  // prevent bubbling of click event up the DOM tree
  ev.stopPropagation();

  // avoid duplicate clicks
  const lastClickTime = Number(btn.dataset.lastclicktime);
  const clickTime = Date.now();
  btn.dataset.lastclicktime = String(clickTime);
  if (clickTime - lastClickTime < 500) {
    // console.log('clickDispatcher ignoring duplicate click');
    return;
  }

  console.assert(typeof btn.handler === 'function',
    `clickDispatcher btn has no handler, btn:`, btn);
  if (typeof btn.handler !== 'function') {
    throw new Error(`button id='${btn.id}' has no handler function!`);
  }

  // always hide the active tooltip
  hideActiveTooltip();

  // if tutorial is on, and tooltip exists, and it has not yet been seen,
  // show it and don't do anything else
  const tutorialOn = getSetting('tutorial');
  const wasTooltipSeen = Boolean(btn.dataset.tooltipseen);
  const tooltipExists = Boolean(tooltip);
  const shouldShowTooltip = tutorialOn && tooltipExists && !wasTooltipSeen;
  if (shouldShowTooltip) {
    tooltip.show();
    setActiveTooltip(tooltip);
    btn.dataset.tooltipseen = '1';
    return;
  }

  // if button is disabled, do nothing further
  if (btn.classList.contains('btn-disabled')) {
    return;
  }

  // if button is a switch, change its state and appearance
  if (isSwitch) {
    btn.switchState = !btn.switchState;
    if (btn.switchState) {
      btn.classList.add('on');
    } else {
      btn.classList.remove('on');
    }
  }

  // speak text
  let speakText = '';
  if (btn.dataset.speakText) {speakText += btn.dataset.speakText;}
  if (isSwitch) {speakText += ', ' + (btn.switchState ? 'on' : 'off');}
  if (speakCommands && speakText.length > 0) {speak(speakText);}

  // call this button's handler
  btn.handler();
}

export {
  clickDispatcher
};
