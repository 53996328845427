// searchlocation.js - search for items at the current location

import * as el from './pageelements.js';
import {
  fetchOverpassCrossing,
  fetchOverpassNearby
} from './overpass-query.js';
import { js2Buildings } from './buildings.js';
import { js2Roads } from './roads.js';
import { js2Places } from './places.js';
import { getClosestItem } from './closestitem.js';
import {
  addWaysToNodes,
  hashItems,
  overpassDataToNodes,
  overpassDataToWays
} from './overpass-common.js';
import { getIntersections } from './intersection.js';
import { speak } from './speech.js';
import { wayNaming } from './waynaming.js';
import { formConnectionGraph } from './connectiongraph.js';
import { getRoadSegments, getRoadsByName } from './roadsegments.js';

/**
 * searchAtLocation return value
 * @typedef {Object} SearchResult
 * @property {Way|null} closestRoad
 * @property {Way[]|null} roadSegments
 * @property {Building|null} closestBuilding
 * @property {Node|null} closestPlace
 * @property {Intersection[]} intersections
 */

/**
 * identify the closest items to the location
 * @param {GeoPoint} location
 * @return {Promise<SearchResult>}
 */
function searchAtLocation (location) {

  let fetchStartTime,
    closestRoad = null,
    roadSegments = null,
    closestBuilding = null,
    closestPlace = null,
    intersections = [];

  return new Promise(resolve => {resolve();})

    .then(() => {
      if (!location) {
        throw {
          message: 'unable to determine your location'
        };
      }
    })

    .then(() => {
      // attempt a fetch from the Overpass API
      fetchStartTime = Date.now();
      return fetchOverpassNearby(location);
    })

    .then(rawJS => {
      // log search time to console
      const fetchEnd = Date.now();
      console.log(`searchAtLocation fetchOverpassNearby completed in ${
        ((fetchEnd - fetchStartTime) / 1000).toFixed(2)
      } seconds.`);
      return rawJS;
    })

    .then(rawJS => {
      // form arrays of item types, show and speak result
      const buildings = js2Buildings(rawJS, location);
      const roads = js2Roads(rawJS, location);
      const places = js2Places(rawJS, location);

      // console.log(`searchAtLocation roads:`, roads);
      // find closest things
      closestRoad = getClosestItem(roads);
      closestBuilding = getClosestItem(buildings);
      closestPlace = getClosestItem(places);

      // identify nearby cross streets to closestRoad
      fetchStartTime = Date.now();
      return fetchOverpassCrossing(closestRoad).then(overpassJS => {
        return overpassJS;
      });
    })

    .then(overpassJS => {
      // log search time to console
      const fetchEnd = Date.now();
      console.log(`searchAtLocation fetchOverpassCrossing completed in ${
        ((fetchEnd - fetchStartTime) / 1000).toFixed(2)
      } seconds.`);
      return overpassJS;
    })

    .then(overpassJS => {
      // get road intersections
      const nodes = overpassDataToNodes(overpassJS);
      const ways = overpassDataToWays(overpassJS);
      const nodesHash = hashItems(nodes);
      const waysHash = hashItems(ways);
      addWaysToNodes(ways, nodesHash);
      const connGraph = formConnectionGraph(
        null,
        closestRoad,
        nodesHash,
        waysHash);

      console.log(`main connGraph:`, connGraph);

      const roadName = wayNaming(closestRoad);
      roadSegments = getRoadsByName(roadName, ways);
      intersections = getIntersections(roadName, connGraph, location, waysHash);
      console.log(`main intersections:`, intersections);

      return {
        closestRoad,
        roadSegments,
        closestBuilding,
        closestPlace,
        intersections
      };
    })

    .catch(err => {
      console.error(`searchAtLocation error:`, err);
      el.divSpinner.classList.add('d-none');
      speak(`There was an error while searching. ${err.message}`);
      return {
        closestRoad,
        roadSegments,
        closestBuilding,
        closestPlace,
        intersections
      };
    });

}

export {
  searchAtLocation
};
