// tutorial.js - manage tutorial mode for the whereami app

import * as el from './pageelements.js';
import { Tooltip } from 'bootstrap';

/**
 * @typedef {Object} BSTooltip
 * @property {function} hide
 */

/** @type {BSTooltip} **/
let _activeTooltip;

// add <button> to tooltip's allowed list
const myAllowList = Tooltip.Default.allowList;
myAllowList.button = [];

/**
 * create tooltip for specified element
 * @param {HTMLElement} el
 * @param {String} title
 * @return {BSTooltip}
 */
function addTooltipToElement (el, title) {
  return new Tooltip(el, {
    trigger: 'manual',
    html: true,
    allowList: myAllowList,
    title
  });
}



/**
 * get the active (shown) tooltip
 * @return {BSTooltip}
 */
function getActiveTooltip () {
  return _activeTooltip;
}

/**
 * store reference to active (shown) tooltip
 * @param {BSTooltip} tooltip
 */
function setActiveTooltip (tooltip) {
  _activeTooltip = tooltip;
}

/**
 * unset and hide the active tooltip
 */
function hideActiveTooltip () {
  if (!_activeTooltip) {return;}
  _activeTooltip.hide();
  _activeTooltip = undefined;
}

// clicking anywhere on the window hides the active tooltip
// NOTE: remember to stop propagation on buttons
window.addEventListener('click', hideActiveTooltip);

export {
  getActiveTooltip,
  setActiveTooltip,
  hideActiveTooltip,
  addTooltipToElement
};
