// show the search result on map

import { appState } from './state.js';
import { addIconMarker, addPolygon, addPolyline, displayMap } from './map.js';
import * as el from './pageelements.js';
import { wayNaming } from './waynaming.js';
import { convertDigitsToWords } from './numtowords.js';

/**
 * show search results on map
 * @param {Way|null} closestRoad
 * @param {Way[]|null} roadSegments
 * @param {Building|null} closestBuilding
 * @param {Node|null} closestPlace
 * @param {Intersection[]|null} intersections
 */
function showResultOnMap (
  closestRoad,
  roadSegments,
  closestBuilding,
  closestPlace,
  intersections
) {
  console.log(`showResultOnMap()`);

  // update map
  const center = appState.mapCenter;
  const {latitude, longitude} = center;
  let zoomlevel = appState.zoomlevel;
  if (zoomlevel < 16) {
    zoomlevel = 16;
    appState.zoomlevel = zoomlevel;
  }
  appState.machineMapInteraction = true;
  displayMap(el.divMap, center, zoomlevel);
  appState.machineMapInteraction = false;

  // add generic marker at search location
  const popupStr = `Search Location:<br>${
    latitude.toFixed(4)
  }, ${
    longitude.toFixed(4)
  }`;
  // addMarker(center, popupStr);
  addIconMarker(
    center,
    '../imgs/target-icon-blue-100.png',
    32,
    32,
    popupStr
  );

  // add closest road segments to map
  if (roadSegments) {
    const popupStr = wayNaming(closestRoad);
    for (const road of roadSegments) {
      addPolyline(
        road.geometry,
        {
          color: '#FF00FF', // magenta line
          weight: 10,
          opacity: 0.5
        },
        popupStr,
        popupStr
      );
    }
  }

  // add building polyline to map
  if (closestBuilding) {
    const {name, housenumber, street, city, geometry, bounds} =
      closestBuilding;
    const bldgCenter = {
      latitude: (bounds.minlat + bounds.maxlat) / 2,
      longitude: (bounds.minlon + bounds.maxlon) / 2
    };
    const popupStr = `${
      name ? name + '<br>' : ''
    }${housenumber} ${street}${
      city ? '<br>' + city : ''
    }`;
    const speakText = `${
      name ? name + ', ' : ''
    }${convertDigitsToWords(housenumber)} ${street}${
      city ? ', ' + city : ''
    }`;
    addPolygon(geometry, {color: 'green'});

    addIconMarker(
      bldgCenter,
      '../imgs/house-icon-100x140.png',
      40,
      56,
      popupStr,
      speakText,
      [20, 56]
    );
  }

  // add intersections to map
  if (intersections) {
    let intCount = 0;
    intersections.forEach(intersection => {
      if (++intCount > 40) {return;}
      const popupStr =
        `${intersection.name}<br>
           and<br>
           ${wayNaming(closestRoad)}`;
      const speakText =
        `${intersection.name}, and ${wayNaming(closestRoad)}`;
      addIconMarker(
        intersection.node.location,
        '../imgs/street-junction-120.png',
        32,
        32,
        popupStr,
        speakText
      );
    });
  }

  // add city center to map
  if (closestPlace) {
    const popupStr = `${closestPlace.name}<br>${closestPlace.place} center`;
    const speakText = `${closestPlace.name} ${closestPlace.place} center`;
    addIconMarker(
      closestPlace.location,
      '../imgs/capitol-building-120.png',
      48,
      48,
      popupStr,
      speakText
    );
  }
}

export {
  showResultOnMap
};
