// showmapbutton.js - handle click on "Show map" button
import { appState } from './state.js';
import * as el from './pageelements.js';
import { deactivateNavButtons } from './pageelements.js';
import { addTarget, displayMap } from './map.js';
import { clickDispatcher } from './clickdispatcher.js';
import { addTooltipToElement } from './tutorial.js';
import { hidePanels } from './panels.js';

let isFirstShowMap = true;

/**
 * handle user click on "show the map" button
 */
function handleShowMapButton () {
  appState.panel = 'map';
  deactivateNavButtons();
  hidePanels();
  // activate show map nav button, setup map, show map panel
  el.btnNavShowMap.classList.add('active');
  // get current location
  const center = appState.mapCenter;
  const target = appState.mapTarget;
  const zoomlevel = appState.zoomlevel;

  appState.machineMapInteraction = true;
  displayMap(el.divMap, center, zoomlevel);
  appState.machineMapInteraction = false;

  // if first time, add target circle to map
  if (isFirstShowMap && target) {
    addTarget(target);
    isFirstShowMap = false;
  }

  // scroll to top
  window.scroll(0, 0);
}

el.btnNavShowMap.handler = handleShowMapButton;
el.btnNavShowMap.addEventListener('click', clickDispatcher);

// add tooltip for tutorial mode
addTooltipToElement(el.btnNavShowMap, `
<div class="pb-3 text-start">
  <p>Show the map, including the most recent search results.</p>
  <img class="img-tutorial"
    src="imgs/map-example.png"
    alt="whereami map example">
</div>`
);

// add speaking text
el.btnNavShowMap.dataset.speakText = 'show map';

export {
  handleShowMapButton
};
