// buildings.js - manage buildings data

import { overpassDataToWays } from './overpass-common.js';
import { toWords, convertDigitsToWords } from './numtowords.js';
import { getSetting } from './settings.js';

/**
 * extract buildings from raw jsData
 * @param {Object} jsData
 * @param {{latitude: number, longitude: number}} myPoint
 * @return {Building[]}
 */
function js2Buildings (
  jsData,
  myPoint,
) {
  let ways = overpassDataToWays(jsData, myPoint);

  // console.log('js2Buildings ways:', ways);

  // filter buildings
  return ways.filter(way => {
    return ('building' in way) &&
      ('street' in way) &&
      ('housenumber' in way);
  });
}

/**
 * generate building info for display and for speech
 * @param {Building|null} bldg
 * @return {{plainText: string, htmlStr: string, failText: string}}
 */
function buildingInfoToDisplay (bldg) {
  let htmlStr = '', plainText = '', failText = '';

  if (!getSetting('showAddress')) {
    return {htmlStr, plainText, failText};
  }

  if (bldg === null) {
    htmlStr += `no address found<br>`;
    failText += ` a street address, `;
    return {htmlStr, plainText, failText};
  }

  const distance = Math.round(bldg.shortestDistance * 3.28084);

  htmlStr +=
`<h5>Address:</h5>
<div class="street-address">
  <p>${bldg.housenumber}</p>
  <p>${bldg.street}</p>
</div>\n`;

  if (getSetting('speakResults')) {
    const numberText = convertDigitsToWords(bldg.housenumber);
    plainText =
      `A nearby address is ${numberText} ${bldg.street}. `;
  }

  return {htmlStr, plainText, failText};
}

export {
  js2Buildings,
  buildingInfoToDisplay
}
