// get all segments with matching road name

const {wayNaming} = require('./waynaming.js');

/**
 * form array of all roads with the matching name
 * @param {String} roadName
 * @param {Way[]|null} roads
 * @return {Way[]|null}
 */
function getRoadsByName(roadName, roads) {
  if (roads === null) {return null;}
  let matchingRoads = [];
  for (const road of roads) {
    if (wayNaming(road) !== roadName) {continue;}
    matchingRoads.push(road);
  }
  // console.log(`getRoadsByName matchingRoads:`, matchingRoads);
  return matchingRoads;
}

export {
  getRoadsByName
};
