// textpanel.js - manage the text panel

import { addPanel } from './panels.js';

let textPanel;
const panelHTML =
`<div id="div-result" class="whereami-panel whereiam-wrapper mt-0 py-5 d-none">
  <p>(no current search)</p>
</div>`;

function initTextPanel () {
  // console.log('initTextPanel()');
  const divWrapper = document.createElement('div');
  divWrapper.innerHTML = panelHTML;
  textPanel = divWrapper.firstElementChild;
  document.body.appendChild(textPanel);
  addPanel('text', 'btn-nav-show-text', showTextPanel, textPanel);
}

function showTextPanel () {
  textPanel.classList.remove('d-none');
  window.scroll(0, 0);
}

export {
  initTextPanel
};
