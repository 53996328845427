// settings.js - manage whereami settings

/**
 * @description constructor for settings
 * @constructor
 */
function WaiSettings () {
  this.speakResults = false;
  this.speakCommands = false;
  this.showRoad = true;
  this.showSpeedlimit = true;
  this.showAddress = true;
  this.showIntersections = true;
  this.showCenter = true;
  this.zoomlevel = null;
  this.tutorial = true;
}

let _settings = new WaiSettings();

/**
 * get a specific setting
 * @param {String} name
 * @return {*}
 */
function getSetting (name) {
  if (typeof _settings[name] === 'undefined') {
    throw `getSetting: setting name '${name}' undefined`;
  }
  return _settings[name];
}

/**
 * set the specific setting to value
 * @param {String} name
 * @param {*} value
 */
function setSetting (name, value) {
  if (typeof _settings[name] === 'undefined') {
    throw `setSetting: setting name '${name}' undefined`;
  }
  _settings[name] = value;
  localStorage.setItem('_whereami_settings', JSON.stringify(_settings));
}

// get stored settings from localstorage (if they are there)
function retrieveSettingsFromLocalstore () {
  const storedSettingsJSON = localStorage.getItem('_whereami_settings');
  if (storedSettingsJSON) {
    Object.assign(_settings, JSON.parse(storedSettingsJSON));
  } else {
    // no stored settings: store default settings to localstorage
    localStorage.setItem('_whereami_settings', JSON.stringify(_settings));
  }
}

retrieveSettingsFromLocalstore();

export {
  getSetting,
  setSetting,
};
