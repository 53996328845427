// overpass-common.js - handle common items in overpass

import { getDistance, getDistanceFromLine } from 'geolib';

const nodeTagsToKeep = ['name', 'place', 'highway', 'ref'];

const waysTagsToKeep = [
  // roads:
  'highway', 'maxspeed', 'name', 'ref', 'surface', 'destination',
  // buildings:
  'building', 'addr:city', 'addr:housenumber', 'addr:street',
];

/**
 * extract ways from overpass JS data
 * @param {Object|null} jsData
 * @param {{latitude: number, longitude: number}} [fromPoint]
 * @return {Array} - array of items
 */
function overpassDataToWays (
  jsData,
  fromPoint = undefined
) {

  if (!jsData) { return null; }

  const elements = jsData.elements;

  const ways = elements.filter(el => el.type === 'way');

  // console.log(`overpassDataToWays: filtered ways:`, ways);

  const resultWays = ways.map(wayItem => {

    let newItem = {
      id: wayItem.id,
      bounds: wayItem.bounds,
      nodes: [...wayItem.nodes],
      geometry: null,
      lineDistances: [],
      shortestDistance: Infinity,
      iLineShortestDistance: null
    };

    // copy geometry from wayItem
    newItem.geometry = wayItem.geometry.map(({lat, lon}) => {
      return {
        latitude: lat,
        longitude: lon
      };
    });

    // add tags as properties of newItem
    for (let key in wayItem.tags) {
      if (!wayItem.tags.hasOwnProperty(key)) { continue; }

      if (waysTagsToKeep.includes(key)) {
        const newKey = key.replace('addr:', '');
        newItem[newKey] = wayItem.tags[key];
      }
    }

    if (!fromPoint) { return newItem; }

    // form array of distances from myPoint to each line segment and
    // determine shortest distance
    const geometryLength = newItem.geometry.length;
    for (let iLine = 0; iLine < geometryLength - 1; iLine++) {
      const distance = getDistanceFromLine(
        fromPoint,
        newItem.geometry[iLine],
        newItem.geometry[iLine + 1],
        0.01
      ) || 0;
      newItem.lineDistances.push(distance);
      if (distance < newItem.shortestDistance) {
        newItem.shortestDistance = distance;
        newItem.iLineShortestDistance = iLine;
      }
    }
    newItem.shortestDistance = Number(
      Math.min(...newItem.lineDistances).toFixed(1));

    return newItem;
  });

  // console.log(`overpassDataToWays resultWays:`, resultWays);
  return resultWays;
}

/**
 * extract nodes from overpass JS data
 * @param {Object|null} jsData
 * @param {{latitude: number, longitude: number}} [fromPoint]
 * @return {*[]} array of items
 */
function overpassDataToNodes (
  jsData,
  fromPoint = undefined
) {

  if (!jsData) { return null; }

  const elements = jsData.elements;
  const jsNodes = elements.filter(el => el.type === 'node');

  const resultNodes = jsNodes.map(node => {
    let newNode = {
      id: node.id,
      location: {
        latitude: node.lat,
        longitude: node.lon
      }
    };

    // add tags as properties of newItem
    if (node.tags) {
      for (const [key, value] of Object.entries(node.tags)) {
        if (nodeTagsToKeep.includes(key) === false) {continue;}
        const newKey = key.replace('addr:', '');
        newNode[newKey] = value;
      }
    }

    // store distance from fromPoint
    if (fromPoint) {
      newNode.shortestDistance = getDistance(fromPoint, newNode.location, 1);
    }
    return newNode;
  });

  // console.log(`overpassDataToNodes resultNodes:`, resultNodes);
  return resultNodes;
}

/**
 * create hash object from array of items
 * @param {Array|null} items
 * @return {Object}
 */
function hashItems (items) {
  if (!items) { return null; }
  const itemsHash = items.reduce((hash, node) => {
    hash[node.id] = node;
    return hash;
  }, {});
  return itemsHash;
}

/**
 * add associated ways to node objects
 * @param {Way[]|null} ways
 * @param {Object} nodesHash
 */
function addWaysToNodes (ways, nodesHash) {
  if (!ways) { return null; }
  ways.forEach(way => {
    way.nodes.forEach(nodeID => {
      const node = nodesHash[nodeID];
      if (!node.ways) { node.ways = []; }
      node.ways.push(way);
    });
  });
}

export { overpassDataToNodes, overpassDataToWays, hashItems, addWaysToNodes };
