// places.js - manage places (city, town) data

import { overpassDataToNodes } from './overpass-common.js';
import { toWords } from './numtowords.js';
import { getSetting } from './settings.js';

/**
 * extract city and town items from raw JS data
 * @param {Object} jsData
 * @param {{latitude: number, longitude: number}} myPoint
 * @return {Array}
 */
export function js2Places (
  jsData,
  myPoint
) {
  let itemsRaw = overpassDataToNodes(jsData, myPoint);

  let places = itemsRaw.filter(item => {
    return ('place' in item) &&
      (item.place === 'city' || item.place === 'town');
  });

  // console.log(`js2Places places:`, places);
  return places;
}

/**
 * form html and text versions of place info
 * @param {Node|null} place
 * @return {{plainText: string, htmlStr: string, failText: string}}
 */
export function placeInfoToDisplay (place) {
  let htmlStr = '', plainText = '', failText = '';

  if (!getSetting('showCenter')) {
    return {htmlStr, plainText, failText};
  }

  if (!place) {
    htmlStr += `no city center found<br>`;
    failText += ` a city center, `;
    return {htmlStr, plainText, failText};
  }

  const distanceMiles = Math.round(place.shortestDistance * 0.000621371);
  const placeType = capitalizeFirstLetter(place.place);

  htmlStr +=
`\n
<h5>Nearby:</h5>
<div class="city-center">
  <p>${place.name}<br>
    ${placeType} Center
  </p>
  <p>${distanceMiles}
    <span class="units">${distanceMiles === 1 ? 'MILE' : 'MILES'}</span>
  </p>
</div>`;

  if (getSetting('showCenter')) {
    plainText = ` The ${place.name} ${place.place} center is about ` +
      `${toWords(distanceMiles)} ${distanceMiles === 1 ? 'mile' : 'miles'} ` +
      `from here. `;
  }

  return {htmlStr, plainText, failText};
}

/**
 * convert string to new string with first letter capitalized
 * @param {String} string
 * @return {string}
 */
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
