import { Modal } from 'bootstrap';
import { compareAppToServer } from './versioncompare.js';

const MODAL_ID = 'div-modal-newversion';
const BTN_CANCEL_ID = 'btn-load-new-version-cancel';
const BTN_LOAD_ID = 'btn-load-new-version';
const MODAL_HTML =
  `<div id="${MODAL_ID}"
        class="modal fade"
        data-bs-backdrop="static"
        tabindex="-1"
    >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">New Version Available</h5>
        </div>
        <div class="modal-body">
          <p>Load the latest version of Where Am I?</p>
        </div>
        <div class="modal-footer">
          <button id="${BTN_CANCEL_ID}" type="button"
                  class="btn btn-secondary">Later</button>
          <button id="${BTN_LOAD_ID}" type="button"
                  class="btn btn-success">Load now</button>
        </div>
      </div>
    </div>
  </div>`;
let modal;

/**
 * create modal and add to body
 * @return {{modal: Modal, btnClose: HTMLButtonElement, btnLoad: HTMLButtonElement}}
 */
function installModal () {
  let btnClose, btnLoad;

  // create modal if necessary
  if (!modal) {
    // create modal div if necessary
    let divModal = document.querySelector(`#${MODAL_ID}`);
    if (!divModal) {
      const divWrapper = document.createElement('div');
      divWrapper.innerHTML = MODAL_HTML;
      divModal = divWrapper.firstElementChild;
      document.body.appendChild(divModal);
    }

    // modal 'close' button
    /** @type {HTMLButtonElement} **/
    btnClose = document.querySelector(`#${BTN_CANCEL_ID}`);
    btnClose.addEventListener('click',
      function (ev) {
        ev.preventDefault();
        modal.hide();
      }
    );

    // modal 'load new version' button
    /** @type {HTMLButtonElement} **/
    btnLoad = document.querySelector(`#${BTN_LOAD_ID}`);
    btnLoad.addEventListener('click',
      function (ev) {
        ev.preventDefault();
        modal.hide();
        setTimeout(
          () => {window.location.reload(true);},
          500
        );
      }
    );

    modal = new Modal(divModal);
  }

  return {
    modal,
    btnClose,
    btnLoad
  }
}

/**
 * show modal if local and server versions don't match
 * @param {String} [eventName]
 */
function versionCheck (eventName = '') {
  console.log(`versionCheck('${eventName}')`);

  // compare versions, show modal if there is a newer version on the server
  compareAppToServer()

    .then(isLatestVersion => {
      if (!isLatestVersion) {
        const {modal} = installModal();
        console.log('There is a new version of Where Am I, showing modal');
        modal.show();
      }
    });
}

export {
  versionCheck
};
