// compare local and server versions

/**
 * local version number must be stored in the DOM head as:
 *    <meta name="version" content="42">
 * server version number is obtained by:
 *    GET /version.txt
 */

const CHECK_INTERVAL = 1000 * 60 * 15; // 15 minutes

/**
 * true if app's local and server versions match
 * @return {Promise<boolean>}
 */
function compareAppToServer () {

  // console.log('compareAppToServer()');
  const now = Date.now();

  // skip test if check was performed recently
  const lastCheckTime =
    Number(localStorage.getItem('_lastVersionCheckTime'));
  if (now - lastCheckTime < CHECK_INTERVAL) {
    // console.log(`  compareAppToServer check was done recently, skipping test`);
    return new Promise(resolve => resolve(true));
  }

  // get local version number from index.html <meta name="version">
  const metaVersion = document.querySelector('meta[name="version"]');
  const versionLocal = (metaVersion) ? Number(metaVersion.content) : null;

  // console.log(`  local version: ${versionLocal}`);

  // get server version number via 'GET /version.txt'
  const versionUrl =
    `${window.location.origin}/version.txt?cachebuster=${Date.now()}`;
  return fetch(versionUrl, {
    method: 'GET',
    mode: 'same-origin'
  })

    .then(response => {
      return response.text();
    })

    .then(versionStr => {
      const versionServer = Number(versionStr);
      // console.log(`  compareAppToServer versionServer: ${versionServer}`);
      localStorage.setItem('_lastVersionCheckTime', String(now));
      return versionServer === versionLocal;
    })

    .catch(err => {
      console.error(`compareAppToServer err:`, err);
    });
}

export {
  compareAppToServer
};
