// settingspanel.js - manage the settings panel

// constants
import { speak } from './speech.js';
import { getSetting, setSetting } from './settings.js';
import { handleSettingsNavButton } from './settingsbutton.js';
import { addPanel } from './panels.js';

const DIV_SETTINGS_ID = 'div-settings',
  FORM_SETTINGS_ID = 'form-settings',
  CHECKBOX_TUTORIAL_ID = 'checkbox-tutorial',
  CHECKBOX_SPEAK_RESULTS = 'checkbox-speak-results',
  CHECKBOX_SPEAK_COMMANDS = 'checkbox-speak-commands',
  CHECKBOX_ROAD = 'checkbox-road',
  CHECKBOX_SPEEDLIMIT = 'checkbox-speedlimit',
  CHECKBOX_ADDRESS = 'checkbox-address',
  CHECKBOX_CENTER = 'checkbox-center',
  CHECKBOX_INTERSECTIONS = 'checkbox-intersections'
;

// relates checkboxes to settings
const checkBoxes = [
  {elID: CHECKBOX_TUTORIAL_ID, settingName: 'tutorial'},
  {elID: CHECKBOX_SPEAK_RESULTS, settingName: 'speakResults'},
  {elID: CHECKBOX_SPEAK_COMMANDS, settingName: 'speakCommands'},
  {elID: CHECKBOX_ROAD, settingName: 'showRoad'},
  {elID: CHECKBOX_SPEEDLIMIT, settingName: 'showSpeedlimit'},
  {elID: CHECKBOX_ADDRESS, settingName: 'showAddress'},
  {elID: CHECKBOX_CENTER, settingName: 'showCenter'},
  {elID: CHECKBOX_INTERSECTIONS, settingName: 'showIntersections'}
];

const settingsHTML =
  `<div id="${DIV_SETTINGS_ID}" class="whereami-panel container py-4 d-none"
     style="max-width: 34rem">

  <h2 class="mb-3">Where Am I – Settings</h2>

  <hr style="height: 2px">

  <form id="${FORM_SETTINGS_ID}">

    <h4>Explanations</h4>
    <p>Show explanation popups when buttons are pressed for the first time</p>
    <div class="mb-4 ps-3 d-flex d-row">
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch"
               id="${CHECKBOX_TUTORIAL_ID}">
        <label class="form-check-label" for="${CHECKBOX_TUTORIAL_ID}">
          Show explanations
        </label>
      </div>
    </div>

    <hr>

    <h4>Items to search for</h4>
    <div class="mb-3 ps-3">
      <div class="form-check mb-3">
        <input id="${CHECKBOX_ROAD}" class="form-check-input" type="checkbox"
               checked value="">
        <label class="form-check-label"
               for="${CHECKBOX_ROAD}">Road</label>
      </div>

      <div class="form-check mb-3">
        <input id="${CHECKBOX_SPEEDLIMIT}" class="form-check-input" type="checkbox"
               checked value="">
        <label class="form-check-label"
               for="${CHECKBOX_SPEEDLIMIT}">Speed limit</label>
      </div>

      <div class="form-check mb-3">
        <input id="${CHECKBOX_ADDRESS}" class="form-check-input" type="checkbox"
               checked>
        <label class="form-check-label"
               for="${CHECKBOX_ADDRESS}">Street address</label>
      </div>

      <div class="form-check mb-3">
        <input id="${CHECKBOX_INTERSECTIONS}" class="form-check-input"
               type="checkbox" checked>
        <label class="form-check-label"
               for="${CHECKBOX_INTERSECTIONS}">Intersections and freeway
          exits</label>
      </div>

      <div class="form-check mb-3">
        <input id="${CHECKBOX_CENTER}" class="form-check-input" type="checkbox"
               checked>
        <label class="form-check-label"
               for="${CHECKBOX_CENTER}">City or town center</label>
      </div>
    </div>

    <hr>

    <h4>Voice response</h4>
    <div class="mb-4 ps-3">
      <div class="form-check form-switch mb-3">
        <input class="form-check-input" type="checkbox" role="switch"
               data-speak-on-text="speak search results, on"
               id="${CHECKBOX_SPEAK_RESULTS}">
        <label class="form-check-label" for="${CHECKBOX_SPEAK_RESULTS}">
          Speak search results</label>
      </div>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch"
               data-speak-on-text="speak things I press, on"
               id="${CHECKBOX_SPEAK_COMMANDS}">
        <label class="form-check-label" for="${CHECKBOX_SPEAK_COMMANDS}">
          Speak things I press</label>
      </div>
    </div>

    <hr>

  </form>


</div>
`;

/**
 * add settings panel to DOM
 * @return {HTMLDivElement}
 */
function addSettingsPanelToDOM () {
  const divWrapper = document.createElement('div');
  divWrapper.innerHTML = settingsHTML;
  const divSettings = divWrapper.firstElementChild;
  document.body.appendChild(divSettings);

  // add element references to checkBoxes
  checkBoxes.forEach(cb => {
    cb.input = document.querySelector('#' + cb.elID);
  });
  return divSettings;
}

/**
 * @description true if input element is checked
 * @param {HTMLInputElement} element
 * @return {boolean}
 */
function isChecked (element) {
  return element.checked;
}

/**
 * @description set form elements to current settings
 */
function transferSettingsToForm () {
  checkBoxes.forEach(cb => {
    cb.input.checked = getSetting(cb.settingName);
  });
}

/**
 * store form element values to settings
 */
function formToSettings () {
  checkBoxes.forEach(cb => {
    setSetting(cb.settingName, isChecked(cb.input));
  });
}

/**
 * do something when checkbox is changed
 */
function checkboxChangeListener () {
  if (isChecked(this) && this.dataset.speakOnText) {
    speak(this.dataset.speakOnText);
  } else if (!isChecked(this) && this.dataset.speakOffText) {
    speak(this.dataset.speakOffText);
  }
}

/**
 * add settings panel to dom, set input values, create listeners
 */
function initSettingsPanel () {

  // console.log('initSettingsPanel()');

  const settingsPanel = addSettingsPanelToDOM();
  addPanel(
    'settings',
    'btn-nav-settings',
    handleSettingsNavButton,
    settingsPanel
  );

  transferSettingsToForm();

  // speak when a checkbox that has `data-speak-[on|off]-text` changes
  checkBoxes.forEach(cb => {
    if (cb.input.dataset.speakOnText || cb.input.dataset.speakOffText) {
      cb.input.addEventListener('change', checkboxChangeListener);
    }
  });

  // update settings when an input changes
  const settingsForm = document.querySelector(`#${FORM_SETTINGS_ID}`);
  settingsForm.addEventListener('change', formToSettings);

}

export {
  initSettingsPanel
};
