import { appState } from './state.js';
import { deactivateNavButtons } from './pageelements.js';
import { clickDispatcher } from './clickdispatcher.js';
import { addTooltipToElement } from './tutorial.js';
import { getPanelElements, hidePanels } from './panels.js';

const btnShowText = document.querySelector('#btn-nav-show-text');

// handle click on "show text" button
function handleShowTextButton () {
  const {show: showPanel} = getPanelElements('text');
  appState.panel = 'text';
  deactivateNavButtons();
  btnShowText.classList.add('active');
  hidePanels();
  showPanel();
}

btnShowText.handler = handleShowTextButton;
btnShowText.addEventListener('click', clickDispatcher);

// add tooltip for tutorial mode
addTooltipToElement(btnShowText, `
  <div class="pb-3 text-start">
    <p>Show the search results in text form.</p>
    <p>Shows road name, street address, etc:</p>
    <img class="img-tutorial"
      src="imgs/text-display-example.png"
      alt="search text display example">
  </div>`
);

export {
  handleShowTextButton
};
