// speech.js - manage speaking text to user

let synth = window.speechSynthesis;
let voices, voicesEnglish;
let voiceNameDefault = 'Karen';
let voiceSelected;
const areVoicesLoaded = () => voices.length > 0;

function populateVoices () {
  voices = synth.getVoices()
    .sort(function (a, b) {
      const aname = a.name.toUpperCase(),
        bname = b.name.toUpperCase();
      if (aname < bname) {return -1;}
      if (aname === bname) {return 0;}
      return 1;
    });

  console.log('populateVoices voices:', voices);

  // remove non-english and indian-english voices
  voicesEnglish = voices.filter(voice => {
    return voice.lang.startsWith('en-') && !(voice.lang.includes('-IN'));
  });

  // find the default voice
  const defaultVoice =
    voicesEnglish.find(voice => voice.name === voiceNameDefault);

  // pick a voice at random
  const iRandom = Math.floor(Math.random() * voicesEnglish.length);
  voiceSelected = voicesEnglish[iRandom];

  console.log(`populateVoices selected voice: ${voiceSelected.name}`);
}

populateVoices();
if (speechSynthesis.onvoiceschanged !== undefined) {
  speechSynthesis.onvoiceschanged = populateVoices;
}

/**
 * speak the supplied text
 * @param {String} text
 */
function speak (text) {

  // console.log(`speak('${text}')`);

  if (!areVoicesLoaded()) {
    console.error(`can't speak, voices not yet loaded`);
    return;
  }

  if (synth.speaking) {
    console.log('speechSynthesis.speaking, waiting 2 seconds before trying' +
      ' again');
    setTimeout(() => {speak(text);}, 2000);
    return;
  }

  let utterThis = new SpeechSynthesisUtterance(text);
  // console.log('speak utterThis:', utterThis);

  utterThis.onstart = function (ev) {
    // console.log('SpeechSynthesisUtterance.onstart');
  };

  utterThis.onend = function (ev) {
    // console.log('SpeechSynthesisUtterance.onend');
  };

  utterThis.onerror = function (ev) {
    console.error('SpeechSynthesisUtterance.onerror ev:', ev);
  };

  utterThis.lang = 'en-US';
  utterThis.pitch = 1;
  utterThis.rate = 0.9;
  utterThis.voice = voiceSelected;
  utterThis.volume = 1.0;
  synth.speak(utterThis);
}

// listen for the first user click and speak something
// this gets around iOS restriction against speaking without user interaction
function handleFirstClick () {
  // speak something silently
  console.log('speaking on the first user click...');
  const click = new SpeechSynthesisUtterance('click... ');
  click.volume = 0;
  speechSynthesis.speak(click);
  document.removeEventListener('click', handleFirstClick);
}

document.addEventListener('click', handleFirstClick);

export {
  speak
};
