// the "warn user before getting geolocation" modal

// the "let user know about geo permission" modal
import { appState } from './state.js';
import { Modal } from 'bootstrap';
import { getCurrentGeoPoint } from './geolocation.js';

let geoModal, geoModalDiv, geoBtnOk;
let geoModalState = null; // 'showing'|'dismissed'

const modalHTML =
`<div id="div-modal-geo" class="modal fade" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <h4>Your Current Location</h4>
        <p>'Where Am I' needs to know the location of your computer or
        smart phone.</p>
        <p>You may need to grant 'Where Am I' permission to use your
          location.</p>
      </div>
      <div class="modal-footer">
        <button
          id="btn-geo-ok"
          type="button"
          class="btn btn-primary">OK</button>
      </div>
    </div>
  </div>
</div>`;

function addModalToBody () {
  const divWrapper = document.createElement('div');
  divWrapper.innerHTML = modalHTML;
  geoModalDiv = divWrapper.firstElementChild;
  geoBtnOk = geoModalDiv.querySelector('#btn-geo-ok');
  document.body.appendChild(geoModalDiv);
  geoModal = new Modal(geoModalDiv);
}

/**
 * get current GeoPoint, showing a modal first if necessary
 */
function getGeoPointWithDialog () {
  // console.log(`getGeoPointWithDialog()`);

  return new Promise((resolve, reject) => {

    // if user has seen and dismissed modal, skip it
    if (appState.userHasSeenGeoModal) {
      // console.log('getGeoPointWithDialog userHasSeenGeoModal, continuing');
      return resolve();
    }

    // modal has been shown and dismissed
    if (geoModalState === 'dismissed') {
      // console.log('getGeoPointWithDialog modal dismissed, continuing');
      return resolve();
    }

    // modal displayed and awaiting dismissal, reject
    if (geoModalState === 'showing') {
      // console.log('getGeoPointWithDialog modal already showing, throwing err');
      throw 'modal already showing';
    }

    // display modal, wait for user dismiss
    if (!geoModal) {
      addModalToBody();
    }
    geoBtnOk.onclick = () => {
      // console.log('modal btn onclick');
      geoModalState = 'dismissed';
      appState.userHasSeenGeoModal = true;
      geoModal.hide();
    };
    geoModalDiv.addEventListener('hidden.bs.modal', () => {
      // console.log(`geoModalDiv 'hidden.bs.modal'`);
      resolve();
    });
    geoModal.show();
    geoModalState = 'showing';
  })

    .then(() => {
      // console.log(`getGeoPointWithDialog then`);
      return getCurrentGeoPoint();
    })

    .catch(err => {
      // console.log('getGeoPointWithDialog err:', err);
      return null;
    });
}

export {
  getGeoPointWithDialog
};
