// setlocaton.js - set the current location

import { getCurrentGeoPoint } from './geolocation.js';
import * as el from './pageelements.js';
import { appState } from './state.js';
import { getGeoPointWithDialog } from './geolocationmodal.js';

/**
 * set the target and map center to current geo point
 * @return {Promise<GeoPoint|null>}
 */
function setTargetAndMapCenterToUsertLocation () {
  return getGeoPointWithDialog()
    .then(coords => {
      // console.log(`coords:`, coords);
      const {latitude, longitude} = coords;
      // update the state
      appState.mapCenter = {latitude, longitude};
      appState.mapTarget = {latitude, longitude};
      return coords;
    })
    .catch(err => {
      console.error(`getLocation error:`, err);
      return null;
    });
}

export {
  setTargetAndMapCenterToUsertLocation
};
