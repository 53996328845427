// settingsbutton.js - handle click on "setings" nav button

// handle click of "Settings" nav button
import { appState } from './state.js';
import { btnNavSettings } from './pageelements.js';
import { deactivateNavButtons } from './pageelements.js';
import { clickDispatcher } from './clickdispatcher.js';
import { addTooltipToElement } from './tutorial.js';
import { hidePanels } from './panels.js';

function handleSettingsNavButton () {
  const settingsPanel =
    document.querySelector('#' + btnNavSettings.dataset.panelId);
  appState.panel = 'settings';
  deactivateNavButtons();
  btnNavSettings.classList.add('active');
  hidePanels();
  settingsPanel.classList.remove('d-none');
  window.scroll(0, 0);
}

btnNavSettings.handler = handleSettingsNavButton;
btnNavSettings.addEventListener('click', clickDispatcher);

// add tooltip for tutorial mode
addTooltipToElement(btnNavSettings, `
  <div class="pb-3 text-start">
    <p>Adjust Where Am I's behavior:</p>
    <ul>
      <li>Show these explanations</li>
      <li>Search for certain items</li>
      <li>Speak search results</li>
    </ul>
  </div>`
);

// add speaking text
btnNavSettings.dataset.speakText = 'settings';

export {
  handleSettingsNavButton
};
