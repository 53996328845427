// map.js - manage map for whereami

import 'leaflet';
import 'leaflet-easybutton';
import { appState } from './state.js';
import { speak } from './speech.js';
import { getSetting } from './settings.js';

let map,
  myTarget, // last user location,
  targetTrace,  // single line from last target location to current location
  crosshair;  // placed at center of map view

// elements added to the map
const mapEl = {
  markers: [],
  circles: [],
  polylines: [],
  polygons: []
};

/**
 * add a popup to map element
 * @param {Object} element
 * @param {String} popupStr
 */
function addPopup (element, popupStr) {
  element.bindPopup(popupStr);
  element.hasPopup = true;
  element.on('click', handleMapElementClick);
}

/**
 * clear all specified map features
 * @param {Boolean} [target]
 * @param {{markers, circles, polylines, polygons}} [elements]
 */
function clearMap (
  target = true,
  elements = {
    markers: true,
    circles: true,
    polylines: true,
    polygons: true
  }
) {
  if (target && myTarget) {
    map.removeLayer(myTarget);
    myTarget = null;
  }
  for (const [propName, clear] of Object.entries(elements)) {
    if (!clear) { continue; }
    mapEl[propName].forEach(el => {
      map.removeLayer(el);
    });
    mapEl[propName].length = 0;
  }
}

/**
 * create circle with optional popup showing lat lon
 * @param {GeoPoint} location
 * @param {Object} options
 * @param {String} [popupStr]
 * @return {Object}
 */
function createCircle (
  location,
  options,
  popupStr = undefined) {
  const latlng = [location.latitude, location.longitude];
  const newCircle = L.circleMarker(latlng, options);
  if (popupStr) {addPopup(newCircle, popupStr);}
  newCircle.lastClickTime = 0;
  return newCircle;
}

/**
 * create a polygon
 * @param {GeoPoint[]} points
 * @param {Object} options
 * @param {String} [popupStr]
 * @param {String} [speakText]
 */
function createPolygon (
  points,
  options,
  popupStr = undefined,
  speakText = undefined
) {
  return createPoly(points, options, popupStr, speakText, L.polygon);
}

/**
 * create a polyline
 * @param {GeoPoint[]} points
 * @param {Object} options
 * @param {String} [popupStr]
 * @param {String} [speakText]
 */
function createPolyLine (
  points,
  options,
  popupStr = undefined,
  speakText = undefined
) {
  return createPoly(points, options, popupStr, speakText, L.polyline);
}

//
/**
 * create a polyline or a polygon
 * @param {GeoPoint[]} points
 * @param {Object} options
 * @param {String} [popupStr]
 * @param {string} [speakText]
 * @param {function} leafletFn (L.polyline || L.polygon)
 */
function createPoly (
  points,
  options,
  popupStr = undefined,
  speakText = undefined,
  leafletFn) {
  // convert Geopoints to LatLngs
  const latlngs = points.reduce(
    (acc,
     {latitude, longitude}
    ) => {
      const latlng = [latitude, longitude];
      acc.push(latlng);
      return acc;
    }, []);

  const line = leafletFn(latlngs, options);
  if (speakText) {line.speakText = speakText;}
  line.lastClickTime = 0;
  if (popupStr) {addPopup(line, popupStr);}
  return line;
}

/**
 * create a marker with an icon
 * @param {GeoPoint} location
 * @param {String} iconUrl
 * @param {Number} sizeX
 * @param {Number} sizeY
 * @param {String} [popupStr]
 * @param {String} [speakText]
 * @param {Number[]} [iconAnchor]
 * @return {Object}
 */
function createIconMarker (
  location,
  iconUrl,
  sizeX,
  sizeY,
  popupStr = undefined,
  speakText = undefined,
  iconAnchor = null
) {
  const latlng = {
    lat: location.latitude,
    lng: location.longitude
  };
  const icon = L.icon({
    iconUrl,
    iconSize: [sizeX, sizeY],
    iconAnchor,
    popupAnchor: [0, 0]
  });
  const marker = new L.marker(latlng, {
    icon,
    clickable: true,
    keyboard: true,
    interactive: true
  });
  if (popupStr) {addPopup(marker, popupStr);}
  if (speakText) {marker.speakText = speakText;}
  marker.lastClickTime = 0;
  return marker;
}

/**
 * handle a click on a map element
 */
function handleMapElementClick () {
  // console.log(`handleMapElementClick this:`, this);

  if (this.hasPopup) {this.openPopup();}

  const now = Date.now();
  if (now - this.lastClickTime < 500) {
    // console.log(`  handleMapElementClick ignoring duplicate click`);
    return;
  }

  this.lastClickTime = now;
  if (this.speakText && getSetting('speakCommands')) {
    speak(this.speakText);
  }

  // log the time of this interaction
  // console.log(`  handleMapElementClick settings lastMapInteractionTime to now`);
  appState.lastMapInteractionTime = now;
}

/**
 * add an icon marker to map
 * @param {GeoPoint} location
 * @param {String} iconUrl
 * @param {Number} sizeX
 * @param {Number} sizeY
 * @param {String} [popupStr]
 * @param {String} [speakText]
 * @param {Number[]} [iconAnchor]
 */
function addIconMarker (
  location,
  iconUrl,
  sizeX,
  sizeY,
  popupStr = undefined,
  speakText = undefined,
  iconAnchor = null
) {
  const marker =
    createIconMarker(
      location,
      iconUrl,
      sizeX,
      sizeY,
      popupStr,
      speakText,
      iconAnchor);
  marker.addTo(map);
  mapEl.markers.push(marker);
}

/**
 * add a polyline to map
 * @param {GeoPoint[]} points
 * @param {Object} options
 * @param {String} [popupStr]
 * @param {String} [speakText]
 */
function addPolyline (
  points,
  options,
  popupStr = undefined,
  speakText = undefined) {
  const polyline = createPolyLine(points, options, popupStr, speakText);
  polyline.addTo(map);
  mapEl.polylines.push(polyline);
}

/**
 * add a polygon to map
 * @param {GeoPoint[]} points
 * @param {Object} options
 * @param {String} [popupStr]
 * @param {String} [speakText]
 */
function addPolygon (
  points,
  options,
  popupStr = undefined,
  speakText = undefined) {
  const polygon = createPolygon(points, options, popupStr, speakText);
  polygon.addTo(map);
  mapEl.polygons.push(polygon);
}

/**
 * add circle to map at location
 * @param {GeoPoint} location
 * @param {String} colorStr
 * @param {Number} [radius=10]
 * @param {String} [popupStr]
 */
function addCircle (
  location,
  colorStr,
  popupStr,
  radius = 10
) {
  const circle = createCircle(
    location,
    {color: colorStr, radius}, popupStr);
  circle.addTo(map);
  mapEl.circles.push(circle);
}

/**
 * add the 'target' circle (current location) to map
 * @param {GeoPoint} location
 * @param {String} [color='#3388ff']
 * @param {Number} [radius=10]
 */
function addTarget (
  location,
  color = '#3388ff',
  radius = 10
) {
  const {latitude, longitude} = location;
  const popupStr = `Latitude, Longitude:<br>
        ${latitude.toFixed(4)},
        ${longitude.toFixed(4)}`;
  const options = {
    color,
    radius,
    fill: true,
    fillColor: '#B3D2FF',
    fillOpacity: 0.9
  };

  // if target exists, add a trace line from prior location
  if (myTarget) {
    const priorLatLng = myTarget.getLatLng();
    const oldPoint = {
      latitude: priorLatLng.lat,
      longitude: priorLatLng.lng
    };
    if (targetTrace) { map.removeLayer(targetTrace);}
    targetTrace = createPolyLine(
      [oldPoint, location],
      {
        color: '#3377F6',
        weight: 4,
        opacity: 0.6
      });
    targetTrace.addTo(map);
    map.removeLayer(myTarget);
    myTarget = undefined;
  }

  myTarget = createCircle(
    location,
    options,
    popupStr);
  myTarget.addTo(map);
}

/**
 * add a generic marker (pin) to map
 * @param {GeoPoint} location
 * @param [popupStr]
 */
function addMarker (
  location,
  popupStr = undefined
) {
  const {latitude, longitude} = location;
  const marker = L.marker(
    [latitude, longitude],
    {
      keyboard: false
    }
  );
  if (popupStr) {
    addPopup(marker, popupStr);
  }
  marker.addTo(map);
  marker.on('click', handleMapElementClick);
  mapEl.markers.push(marker);
}

/**
 * display the map
 * @param {HTMLDivElement} divMap
 * @param {GeoPoint} location - center map here
 * @param {Number} zoomlevel
 */
function displayMap (
  divMap,
  location,
  zoomlevel
) {

  // unhide the map
  divMap.classList.remove('d-none');

  // move map to location
  moveMapCenter(location, zoomlevel);
}

/**
 * move map center
 * @param {GeoPoint} center
 * @param {Number} zoom
 */
function moveMapCenter (center, zoom) {
  if (!center) {return;}
  map.setView(
    [center.latitude, center.longitude],
    zoom, {
      duration: 1.0
    }
  );
}

/**
 * set zoom level of map
 * @param {Number} level
 */
function zoomMap (level) {
  map.setZoom(level);
}

/**
 * buton handler callback function
 * @callback CbHandler
 * @param {Object} btn
 * @param {Object} map
 */

/**
 * add custom button to map
 * @ref https://github.com/CliffCloud/Leaflet.EasyButton/blob/master/USAGE.md
 * @param {String} icon
 * @param {CbHandler} clickHandler
 * @param {String} [title]
 * @param {String} [position] 'topleft'|'topright'|'bottomleft'|'bottomright'
 * @return {HTMLButtonElement}
 */
function addMapButton (
  icon,
  clickHandler,
  title = '',
  position = 'topleft') {
  const myButton = L.easyButton(icon, clickHandler);
  myButton.addTo(map);

  if (title) {myButton.button.title = title;}

  return myButton.button;
}

/**
 * return the largest available map zoom
 * @return {Number}
 */
function getMapMaxZoom () {
  return map.getMaxZoom();
}

/**
 * the smallest available map zoom
 * @return {Number}
 */
function getMapMinZoom () {
  return map.getMinZoom();
}

/**
 * add an event listener to the map
 * @param {String} eventName
 * @param {callback} listener
 */
function addMapEventListener (eventName, listener) {
  map.on(eventName, listener);
}

/**
 * @description create a new map with optional marker
 * @param {HTMLDivElement} divMap
 * @param {GeoPoint} location
 */
function createMap (
  divMap,
  location,
) {
  // console.log('createMap()');
  const divID = divMap.id,
    {latitude, longitude} = location;
  let restoreDNone = false;
  let zoomlevel = appState.zoomlevel;

  // div must not have class 'd-none'
  if (divMap.classList.contains('d-none')) {
    divMap.classList.remove('d-none');
    restoreDNone = true;
  }

  // create map, set the map view location and zoom level
  map = L.map(divID, {
    attributionControl: false,
    zoomControl: false,
    doubleClickZoom: false,
    minZoom: 5,
    touchZoom: false
  })
    .setView([latitude, longitude], zoomlevel);

  // add OSM layer to map
  L.tileLayer(
    'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  ).addTo(map);

  // add crosshair marker that floats over map center
  // ref: https://gis.stackexchange.com/a/90230/95335
  const crosshairIcon = L.icon({
    iconUrl: '../imgs/crosshair-icon-100.png',
    iconSize: [32, 32],
    iconAnchor: null,
    popupAnchor: [0, 0]
  });
  crosshair = new L.marker(map.getCenter(), {
    icon: crosshairIcon,
    clickable: false,
    keyboard: false,
    interactive: false
  });
  crosshair.addTo(map);

  // Move crosshair to center of map when user pans
  map.on('move', function (e) {
    crosshair.setLatLng(map.getCenter());
  });

  // whenever zoom level changes, store it to state
  map.on('zoomend', () => {
    zoomlevel = map.getZoom();
    appState.zoomlevel = zoomlevel;
  });

  // whenever map is moved, store the new map center to state
  map.on('moveend', () => {
    const {lat: latitude, lng: longitude} = map.getCenter();
    appState.mapCenter =  {latitude, longitude};
  });

  // restore 'd-none'
  if (restoreDNone) {
    divMap.classList.add('d-none');
  }

  // console.log(`  createMap map:`, map);

}

export {
  createMap,
  displayMap,
  addCircle,
  addPolyline,
  addPolygon,
  addMarker,
  addIconMarker,
  addTarget,
  addMapButton,
  clearMap,
  moveMapCenter,
  zoomMap,
  getMapMaxZoom,
  getMapMinZoom,
  addMapEventListener
};
