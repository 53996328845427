// cache search results in sessionStorage

const hash = require('object-hash');

function hashQuery (query) {
  return hash(query);
}

/**
 * retrieve cached query response, if it exists
 * @param {String} query
 * @return {Object|null}
 */
function getCachedQueryResponse (query) {
  const queryHash = hashQuery(query);
  const resCachedJSON = window.sessionStorage.getItem(queryHash);
  return (resCachedJSON) ? JSON.parse(resCachedJSON) : null;
}

/**
 * store query response into cache if possible
 * @param {string} query
 * @param {Object} result
 */
function cacheQueryResult (query, result) {
  const queryHash = hashQuery(query);
  const resultJSON = JSON.stringify(result);
  try {
    window.sessionStorage.setItem(queryHash, resultJSON);
  } catch (err) {
    console.error(`cacheQueryResult sessionStorage err:`, err);
    console.log('cacheQueryResult: clearing cache');
    clearCache();
  }
}

function clearCache () {
  window.sessionStorage.clear();
}

export {
  getCachedQueryResponse,
  cacheQueryResult,
  clearCache
};
